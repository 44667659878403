import { HttpClient, HttpParams } from "@angular/common/http";
import {
  Component,
  Directive,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  FormArray,
  ValidatorFn,
  AbstractControl,
} from "@angular/forms";
import { BodyIngresoReclamo } from "src/app/models/bodyIngresoReclamo";
import {
  ApplicationProvider,
  EndpointProvider,
} from "src/app/providers/providers";
import { PdkMaker } from "src/app/services/pdf-make";
import { NgxSpinnerService } from "ngx-spinner";
import { DialogAlertComponent } from "src/app/shared/modals/dialog-alert/dialog-alert.component";
import { MatDialog } from "@angular/material";
import { ReqProdCliente } from "../../models/ReqProdCliente";
import datajson from "../../../assets/config.json";
import { BodyConsultaCliente } from "src/app/models/BodyConsultaCliente";
import { Router } from "@angular/router";
import { DatePipe, formatDate } from "@angular/common";
import { noUndefined } from "@angular/compiler/src/util";
import { CamposAdicionales } from "../../models/camposAdicionales";
import { stringToKeyValue } from "@angular/flex-layout/extended/typings/style/style-transforms";

@Component({
  selector: "app-formulario-reclamo",
  templateUrl: "./formulario-reclamo.component.html",
  styleUrls: ["./formulario-reclamo.component.css"],
})
export class FormularioReclamoComponent implements OnInit {
  @ViewChild("infile", { static: true }) infile: Element;
  formReclamoGroup: FormGroup;
  formDocFirmado: FormGroup;
  formCamposAdicionales: FormGroup;
  dataCliente: any;
  productos: any;
  tipoCuenta: any = [];
  productosCliente: any = [];
  cuentasCliente: any = [];
  motivos: any = [];
  servicios: any = [];
  tipoDoc: any = [
    {
      id: "C",
      valor: "Cédula",
    },
    {
      id: "R",
      valor: "Ruc",
    },
    {
      id: "P",
      valor: "Pasaporte",
    },
  ];
  // Variable que permite cambiar la longitud de caracteres a ingresar en input identificacion
  length = "10";
  // Permite validar si la identificacion contiene solo digitos (CED, RUC) o digitos y letras (PAS)
  isNumber = true;
  // Contiene el modelo de campos que deben ser enviados como body en el Servicio Web
  body: BodyIngresoReclamo;
  // Contador para permitir un solo check a la vez en tabla de productos
  contador = 0;
  // Indice de documento elegido
  indexListaDocs: number;
  // Banderas para archivos subidos
  chekcuenta = false;
  docCed = false;
  docTrx = false;
  docCarta = false;
  docDenuncia = false;
  codOperacion = "";
  cheques = false;
  atm = false;
  tdebito = false;
  tcredito = false;
  operacionCredito = false;
  centralRiesgo = false;
  certifDeposito = false;
  tarifaServicio = false;
  error: any = [];
  file1 = [];
  file2 = [];
  file3 = [];
  file4 = [];
  flagBtnGuardar: boolean;
  flagTipoPersona: boolean;
  idReclamo: string;
  docFirmado = [];
  parpadeo: string;
  agencias: any = [];
  ciudades: any = [];
  /*tipoCuenta: any = [
    {
      id: '1',
      valor: 'AHO'
    },
    {
      id: '2',
      valor: 'CTE'
    },
    {
      id: '3',
      valor: 'TRJ'
    }
  ];*/
  noCuenta: any = [];
  jsonConfig: any;
  canal: any[] = [
    {
      id: "76",
      valor: "Internet",
    },
  ];

  noDNI = [
    "1111111111",
    "2222222222",
    "3333333333",
    "4444444444",
    "5555555555",
    "6666666666",
    "7777777777",
    "8888888888",
    "9999999999",
    "0000000000",
  ];

  reqPCliente: ReqProdCliente;
  flagTable: boolean = false;
  flagCampos: boolean = false;
  dataCamposAdicionales: any;
  contadorText = 0;
  ms: any;
  ms1: any;
  showText: boolean = false;
  showNum: boolean = false;
  msErrorNum: any;
  msErrorText: any;
  sizeGeneral: any;
  tamanioArchivos: any;
  cantidadArchivoCargado: any;
  tituloGrid: any;
  dataConfig: any = datajson;
  dataCajero: any;
  bodyCliente: BodyConsultaCliente; //KBastidas
  formInicio: FormGroup; //KBastidas
  dataServicioTempCliente: any; //KBastidas
  isActiveUrl: boolean = false; //KBastidas
  almacenarArchivos: any = [];
  contArchivos = 0;
  contenedorArchivo: any = [];
  filtro: any;
  regex: RegExp;
  tarjetaCredito: any[];
  tarjetaTCSeleccionada: any;
  tarjetaDebito: any[];
  tarjetaTBSeleccionada: null;
  fechasShow: boolean;
  detalleTransCuentas: boolean;
  paginacion: boolean;
  btnLlamado: boolean;
  btnAutorizacion: boolean;
  btnCompensadas: boolean;
  btnIngresoManual: boolean;
  tarjetasTC: boolean;
  ingresoManualTable: boolean;
  transaccionesSeleccionadas: any = {}; // Inicializar como un objeto vacío
  cuentaSeleccionada: any;
  cuentasTC: any[];
  flagTableCuentasTC: boolean;
  numeroCuentaSeleccionada: string;
  tarjetaTDTCSeleccionada: any;
  botonesTransacciones: boolean;
  transacciones: any[];
  transaccionesPorPagina: number = 10;
  paginaActual: any = 0;
  tipoTransaccion: any;
  totalTransacciones: number;
  parametroEncontrado;
  provincias: any;
  provinciaId: any;
  cantonId: any;
  parametroServicio: any;
  estadosTC: any;
  generoId: any;
  tarjetasTD: boolean;
  clienteNuevo: any;
  nemonicos: any;
  parametrosTCTD: any;
  ocultarSegundoItem: boolean;
  tarjeta: any;
  tipocuentades: any;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private coreService: ApplicationProvider,
    private pdfMaker: PdkMaker,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private router: Router,
    private el: ElementRef,
    private endPoint: EndpointProvider
  ) {
    // Uso de ReactiveForms con expresiones regulares para validaciones de inputs
    this.formReclamoGroup = formBuilder.group({
      tipoCuenta: [{ value: "", disabled: false }, [Validators.required]],
      producto: [{ value: "", disabled: false }, [Validators.required]],
      servicio: [{ value: "", disabled: false }, [Validators.required]],
      motivo: [{ value: "", disabled: false }, [Validators.required]],
      fechaProblema: [{ value: "", disabled: false }, [Validators.required]],
      montoReclamo: [{ value: null, disabled: false }], //[Validators.required, Validators.pattern(/^[0-9]*$/)]
      descHechos: [
        { value: "", disabled: false },
        [
          Validators.required,
          Validators.pattern(/^[A-Za-zÁáÉéÍíÓóÚúñÑ !?/%$-@#()*¡¿="",.0-9]+$/),
        ],
      ],
      peticion: [
        { value: "", disabled: false },
        [
          Validators.required,
          Validators.pattern(/^[A-Za-zÁáÉéÍíÓóÚúñÑ !?/%$-@#()*¡¿="",.0-9]+$/),
        ],
      ],

      reclamo: [
        { value: "", disabled: true },
        [Validators.required, Validators.pattern(/^[A-Za-zÁáÉéÍíÓóÚú #0-9]+$/)],
      ],
      oficinaIngreso: [{ value: "", disabled: false }],
      fecha: [
        { value: new Date().toISOString().substring(0, 10), disabled: true },
        [Validators.required],
      ],
      canal: [{ value: "", disabled: false }],
      tipoRecp: [{ value: "", disabled: false }],
      ruc: [{ value: "", disabled: true }, [Validators.required]],
      razonSocial: [{ value: "", disabled: true }, [Validators.required]],

      tipoDocumento: [{ value: "", disabled: true }, [Validators.required]],
      identificacion: [
        { value: "", disabled: true },
        [
          Validators.required,
          Validators.minLength(10),
          Validators.pattern(/^[0-9]*$/),
        ],
      ],
      nombres: [
        { value: "", disabled: true },
        [
          Validators.pattern(/^[a-zA-ZÁáÉéÍíÓóÚú ]+$/),
          Validators.maxLength(30),
        ],
      ],
      direccion: [
        { value: "", disabled: true },
        [Validators.required, Validators.pattern(/^[A-Za-zÁáÉéÍíÓóÚú #0-9]+$/)],
      ],
      oficialDeCuenta: [
        { value: "", disabled: true },
        [Validators.pattern(/^[a-zA-ZÁáÉéÍíÓóÚú ]+$/)],
      ],
      telefono1: [
        { value: "", disabled: true },
        [
          Validators.required,
          Validators.minLength(9),
          Validators.pattern(/^[0-9]*$/),
        ],
      ],
      telefono2: [
        { value: "", disabled: true },
        [
          Validators.required,
          Validators.minLength(9),
          Validators.pattern(/^[0-9]*$/),
        ],
      ],
      correo: [
        { value: "", disabled: false },
        [
          Validators.required,
          Validators.pattern(
            "^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$"
          ),
        ],
      ],
      lineaNegocio: [
        { value: "", disabled: true },
        [Validators.pattern(/^[a-zA-ZÁáÉéÍíÓóÚú ]+$/)],
      ],
      provincia: [
        { value: "", disabled: true },
        [Validators.pattern(/^[a-zA-ZÁáÉéÍíÓóÚú ]+$/)],
      ],
      ciudad: [
        { value: "", disabled: true },
        [Validators.pattern(/^[a-zA-ZÁáÉéÍíÓóÚú ]+$/)],
      ],
      ciudadEntidad: [
        { value: "", disabled: true },
        [Validators.pattern(/^[a-zA-ZÁáÉéÍíÓóÚú ]+$/)],
      ],
      telOficial: [
        { value: "", disabled: false },
        [Validators.minLength(9), Validators.pattern(/^[0-9]*$/)],
      ],

      fechaHasta: [""],
      fechaDesde: [""],

      //fechaNacimiento
      fechaNacimiento: [{ value: "", disabled: true }],

      //Edad
      edad: [{ value: "", disabled: true }],

      //sexo
      sexo: [{ value: "", disabled: true }],

      //codigoSib
      sibCode: [{ value: "", disabled: true }],

      camposAdicionales: formBuilder.array([]),
      doc1: [{ value: "", disabled: false }],
      doc2: [{ value: "", disabled: false }],
      doc3: [{ value: "", disabled: false }],
      doc4: [{ value: "", disabled: false }],
    });
    this.formDocFirmado = formBuilder.group({
      docFirmado: [{ value: "", disabled: false }, [Validators.required]],
    });
  }

  ngAfterViewInit() {
    let val = sessionStorage.origen;
  }

  //calcular edad cada ves que se cambie la fecha de nacimiento
  calcularEdad(fechaNacimiento) {
    fechaNacimiento = new Date(fechaNacimiento);

    let fechaActual = new Date();
    let edad = fechaActual.getFullYear() - fechaNacimiento.getFullYear();
    let mes = fechaActual.getMonth() - fechaNacimiento.getMonth();
    if (
      mes < 0 ||
      (mes === 0 && fechaActual.getDate() < fechaNacimiento.getDate())
    ) {
      edad--;
    }
    this.formReclamoGroup.controls["edad"].setValue(edad);
  }

  async ngOnInit() {
    this.filtro = this.dataConfig["filtro"];
    this.regex = new RegExp("[" + this.filtro + "]", "g");
    this.parpadeo = "card card-background parpadea";
    this.flagTable = false;
    this.flagBtnGuardar = false;
    this.seleccionCiudades();
    this.cantidadArchivoCargado = 0;

    const en: any = await (
      await this.endPoint.getTokenApiGateway()
    ).toPromise();
    this.endPoint.setToken(en.token_type + " " + en.access_token);
    const data: any = {
      TokenId: "",
    };
    data.TokenId = en.access_token;
    const dataServicioTemp: any = await this.coreService
      .httpProductos(data)
      .toPromise();
    this.sizeFile();
    this.productos = dataServicioTemp;

    //quitar los con id 14 y 15
    this.productos = this.productos.filter(
      (producto) =>
        producto.IdProducto != 14 &&
        producto.IdProducto != 15 &&
        producto.IdProducto != 24 &&
        producto.IdProducto != 25
    );

    /*Formulario ATC Recepcion de Parametros Por Url Ini */
    const params = this.obtenerParametros(window.location);
    if (params["identificacion"] !== undefined) {
      sessionStorage.origen = "call_Center";
      this.eliminaParametrosUrl();
      this.isActiveUrl = true;
      let identificacion = params["identificacion"];
      await this.bodyConsultaCliente(params);
      const en: any = await (
        await this.endPoint.getTokenApiGateway()
      ).toPromise();
      this.endPoint.setToken(en.token_type + " " + en.access_token);
      this.dataServicioTempCliente = await this.coreService
        .httpConsultaCliente(this.bodyCliente)
        .toPromise();
      this.configReclamoByCallCenter(identificacion, params);
      /*Formulario ATC Recepcion de Parametros Por Url Fin */
    } else {
      this.dataCliente = JSON.parse(sessionStorage.dataCliente);

      if (this.dataCliente.esEmpleado == 1) {
        this.alertaPSM(
          "La cédula corresponde a un funcionario del banco, no puede ingresar un reclamo"
        );
        this.router.navigate(["/consultas"]);
        return;
      }
      await this.consultaNuevoCliente();

      if (sessionStorage.origen === "call_Center") {
        this.formReclamoGroup.controls.canal.setValue(
          this.dataConfig["descCallCenter"]
        );
        this.setTipoRecepcionByOrigen(this.dataConfig["descTipoRecepcion"]);
      } else {
        this.formReclamoGroup.controls.canal.setValue(
          this.dataConfig["descCanal"]
        );
        let value =
          sessionStorage.origen == "defensor"
            ? "Web/Defensor del Cliente"
            : "Web/Cliente";
        this.setTipoRecepcionByOrigen(value);
      }
    }

    // Para llenar la tabla de productos del cliente

    await this.reqProdCliente();
    const enprd: any = await (
      await this.endPoint.getTokenApiGateway()
    ).toPromise();
    this.endPoint.setToken(enprd.token_type + " " + enprd.access_token);
    this.reqPCliente.TokenId = enprd.access_token;
    const dataServicioPrdTemp: any = await this.coreService
      .httpProductosCliente(this.reqPCliente)
      .toPromise();
    //tener datos de productos
    this.cuentasCliente = dataServicioPrdTemp;
    //recorrer mis productos para cargar combo
    for (let i = 0; i < dataServicioPrdTemp.length; i++) {
      this.tipoCuenta.push({
        id: dataServicioPrdTemp[i].Tipo,
        valor: dataServicioPrdTemp[i].TipoCuenta,
      });
    }
  }
  async consultaNuevoCliente() {
    var tipoDocumento = sessionStorage.tipoPersona;
    var identificacion = this.dataCliente.identificacionCliente;

    const data: any = {
      identificationNumber: identificacion,
      identificationType: tipoDocumento,
    };

    const en: any = await (
      await this.endPoint.getTokenApiGateway()
    ).toPromise();
    this.endPoint.setToken(en.token_type + " " + en.access_token);
    this.spinner.show();
    this.coreService.httpClienteNuevo(data).subscribe(
      (data: any) => {
        this.clienteNuevo = data.data;

        if (this.clienteNuevo != null) {
          if (
            this.clienteNuevo.lastDirection.description != null &&
            this.clienteNuevo.lastDirection.description != "0" &&
            this.clienteNuevo.lastDirection.description != " "
          ) {
            this.dataCliente.direccion =
              this.clienteNuevo.lastDirection.description;
          }

          if (
            this.clienteNuevo.lastCellphoneNumber != null &&
            this.clienteNuevo.lastCellphoneNumber != "0" &&
            this.clienteNuevo.lastCellphoneNumber != " "
          ) {
            this.dataCliente.telefono1 = this.clienteNuevo.lastCellphoneNumber;
          }

          if (
            this.clienteNuevo.lastDirection.telephoneNumber != null &&
            this.clienteNuevo.lastDirection.telephoneNumber != "0" &&
            this.clienteNuevo.lastDirection.telephoneNumber != " "
          ) {
            this.dataCliente.telefono2 =
              this.clienteNuevo.lastDirection.telephoneNumber;
          }

          if (
            this.clienteNuevo.sibCode != null &&
            this.clienteNuevo.sibCode != "0" &&
            this.clienteNuevo.sibCode != " "
          ) {
            this.dataCliente.sibCode =
              sessionStorage.tipoPersona == "P"
                ? this.clienteNuevo.sibCode
                : "";
          }

          if (
            this.clienteNuevo.provinceCode != null &&
            this.clienteNuevo.provinceCode != "0" &&
            this.clienteNuevo.provinceCode != " "
          ) {
            this.dataCliente.provincia = this.clienteNuevo.provinceCode;
          } else {
            const dialogRef = this.dialog.open(DialogAlertComponent, {
              data: {
                name: "Información incompleta, campo provincia",
                content:
                  "Por favor, comunicarse con el Contact Center para actualizar su información o acercarse a la agencia más cercana.",
                buttons: false,
                btnAceptar: false,
                btnCancelar: true,
              },
            });
            dialogRef.afterClosed().subscribe((result) => {
              this.router.navigate(["/consultas"]);
            });
          }

          if (
            this.clienteNuevo.cantonCode != null &&
            this.clienteNuevo.cantonCode != "0" &&
            this.clienteNuevo.cantonCode != " "
          ) {
            this.dataCliente.ciudad = this.clienteNuevo.cantonCode;
          } else {
            const dialogRef = this.dialog.open(DialogAlertComponent, {
              data: {
                name: "Información incompleta, campo ciudad",
                content:
                  "Por favor, comunicarse con el Contact Center para actualizar su información o acercarse a la agencia más cercana.",
                buttons: false,
                btnAceptar: false,
                btnCancelar: true,
              },
            });
            dialogRef.afterClosed().subscribe((result) => {
              this.router.navigate(["/consultas"]);
              return;
            });
          }
        }

        this.llamarDataNecesaria();

        this.spinner.hide();
      },
      (error) => {},
      () => {
        this.spinner.hide();

        this.mostrarCamposPorTipoPersona();
        this.llenarFormularioDatosCliente();
      }
    );
  }
  async llamarDataNecesaria() {
    await this.llamarProvincias();
    await this.llamarCantones();
    await this.llamarParametros();
    await this.llamarEstados();
    await this.llamarGeneros();
    //TODO
    await this.llamarParametrosQ01();
  }
  async llamarParametrosQ01() {
    const en: any = await (
      await this.endPoint.getTokenApiGateway()
    ).toPromise();
    const data: any = {
      token: {
        TokenId: "",
      },
    };

    this.endPoint.setToken(en.token_type + " " + en.access_token);
    data.TokenId = en.access_token;

    this.spinner.show();
    this.coreService.httpParametrosQ01(data).subscribe(
      (data: any) => {
        this.nemonicos = data.filter(
          (parametro) => parametro.Parametro == "Nemónico TC/TD"
        );

        this.parametrosTCTD = data.filter(
          (parametro) => parametro.Parametro == "TC/TD"
        );
      },
      (error) => {
        this.alertaValidacion("Error al consultar parametrosQ01");
      },
      () => {
        this.spinner.hide();
      }
    );
  }
  async llamarEstados() {
    const en: any = await (
      await this.endPoint.getTokenApiGateway()
    ).toPromise();
    const data: any = {
      token: {
        TokenId: "",
      },
    };

    this.endPoint.setToken(en.token_type + " " + en.access_token);
    data.TokenId = en.access_token;

    this.spinner.show();
    this.coreService.httpEstados(data).subscribe(
      (data: any) => {
        this.estadosTC = data;
      },
      (error) => {
        this.alertaValidacion("Error al consultar estados");
      },
      () => {
        this.spinner.hide();
      }
    );
  }
  async llamarProvincias() {
    const en: any = await (
      await this.endPoint.getTokenApiGateway()
    ).toPromise();
    const data: any = {
      token: {
        TokenId: "",
      },
    };
    this.spinner.show();

    this.endPoint.setToken(en.token_type + " " + en.access_token);
    data.TokenId = en.access_token;
    this.spinner.show();
    this.coreService.httpProvincias(data).subscribe(
      (data: any) => {
        if (
          this.clienteNuevo.provinceCode != null &&
          this.clienteNuevo.provinceCode != "0" &&
          this.clienteNuevo.provinceCode != " "
        ) {
          const provincia = data.find(
            (provincia) =>
              provincia.CodeProvincia == this.clienteNuevo.provinceCode
          );

          this.provinciaId = provincia.CodeProvincia;
          this.formReclamoGroup.controls["provincia"].setValue(
            provincia.Descripcion
          );
        } else {
          var provinciaDesc = this.dataCliente.provincia;
          provinciaDesc = data.find(
            (provincia) => provincia.CodeProvincia == this.dataCliente.provincia
          );

          this.provinciaId = provinciaDesc.CodeProvincia;
          this.formReclamoGroup.controls["provincia"].setValue(
            provinciaDesc.Descripcion
          );
        }
      },
      (error) => {
        this.alertaValidacion("Error al consultar provincias");
      },
      () => {
        this.spinner.hide();
      }
    );
  }
  async llamarCantones() {
    const en: any = await (
      await this.endPoint.getTokenApiGateway()
    ).toPromise();
    const data: any = {
      token: {
        TokenId: "",
      },
    };

    this.endPoint.setToken(en.token_type + " " + en.access_token);
    data.TokenId = en.access_token;
    this.coreService.httpCantones(data).subscribe(
      (data: any) => {
        if (
          this.clienteNuevo != null &&
          this.clienteNuevo.cantonCode != null &&
          this.clienteNuevo.cantonCode != "0" &&
          this.clienteNuevo.cantonCode != " "
        ) {
          const canton = data.find(
            (canton) => canton.CodeCiudad == this.clienteNuevo.cantonCode
          );
          if (canton) {
            console.log("canton code", canton);
            this.cantonId = canton.IdCiudad;
            console.log("canton id", this.cantonId);
            this.formReclamoGroup.controls["ciudad"].setValue(
              canton.Descripcion
            );
          }
        } else {
          var cantonDesc = this.dataCliente.ciudad;
          cantonDesc = data.find(
            (canton) => canton.Descripcion == this.dataCliente.ciudad
          );
          if (cantonDesc) {
            console.log("cantonn", cantonDesc);
            this.cantonId = cantonDesc.IdCiudad;
            this.formReclamoGroup.controls["ciudad"].setValue(
              cantonDesc.Descripcion
            );
          }
        }

        this.spinner.hide();
      },
      (error) => {
        this.alertaValidacion("Error al consultar cantones");
      },
      () => {
        this.spinner.hide();
      }
    );
  }
  async llamarParametros() {
    const en: any = await (
      await this.endPoint.getTokenApiGateway()
    ).toPromise();
    const data: any = {
      token: {
        TokenId: "",
      },
    };

    this.endPoint.setToken(en.token_type + " " + en.access_token);
    data.TokenId = en.access_token;
    this.spinner.show();

    this.coreService.httpParametros(data).subscribe(
      (data: any) => {
        this.parametroServicio = data;
      },
      (error) => {
        this.alertaValidacion("Error al consultar parametros");
      },
      () => {
        this.spinner.hide();
      }
    );
  }
  async llamarGeneros() {
    const en: any = await (
      await this.endPoint.getTokenApiGateway()
    ).toPromise();
    const data: any = {
      token: {
        TokenId: "",
      },
    };

    this.endPoint.setToken(en.token_type + " " + en.access_token);
    data.TokenId = en.access_token;
    this.spinner.show();
    this.coreService.httpGeneros(data).subscribe(
      (data: any) => {
        const generos = data;

        var genero = this.dataCliente.sexo;

        if (genero == null) {
          return;
        }

        if (genero == "M") {
          genero = "MASCULINO";
        } else if (genero == "F") {
          genero = "FEMENINO";
        } else {
          genero = "NO APLICA";
        }
        //obtener el id del genero
        this.generoId = generos.find(
          (generoA) => generoA.descripcionCatalogo === genero
        ).idTipoCatalogo;

        this.formReclamoGroup.controls["sexo"].setValue(genero);
      },
      (error) => {
        this.alertaValidacion("Error al consultar generos");
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  validaFecha(event) {
    //let value = this.formReclamoGroup.controls.camposAdicionales.value[5];
    //let fechaProblema = value["ca-fecha"];
    let fechaProblema = event.target.value;
    let fechaActual = new Date();
    let localDate = formatDate(fechaActual, "yyyy-MM-dd", "en_US");
    if (fechaProblema > localDate) {
      this.alertaValidacion("La fecha no puede ser mayor a la Fecha actual");
      event.target.value = null;
      return null;
    }
    // iro-04102022
    let fechaAMin = new Date("2000-01-02");
    let localDateMin = formatDate(fechaAMin, "yyyy-MM-dd", "en_US");
    if (fechaProblema < localDateMin) {
      this.alertaValidacion(
        "La fecha no puede ser menor a la Fecha seleccionada"
      );
      event.target.value = null;
      return null;
    }
  }

  configReclamoByCallCenter(identificacion, params) {
    if (
      this.dataServicioTempCliente["identificacionCliente"] === identificacion
    ) {
      sessionStorage.tipoPersona =
        this.retornarSufijoTipoDocumento(identificacion);
      this.mostrarCamposPorTipoPersona();
      sessionStorage.dataCliente = JSON.stringify(this.dataServicioTempCliente);
      this.dataCliente = JSON.parse(sessionStorage.dataCliente);

      if ((this.dataCliente.esEmpleado = 1)) {
        this.alertaPSM(
          "La cédula corresponde a un funcionario del banco, no puede ingresar un reclamo"
        );
        this.router.navigate(["/consultas"]);
        return;
      }

      this.consultaNuevoCliente();
      if (params["origen"] === "call_Center") {
        this.formReclamoGroup.controls.canal.setValue(
          this.dataConfig["descCallCenter"]
        );
        this.setTipoRecepcionByOrigen("Web/Cliente");
      }
    }
  }
  setTipoRecepcionByOrigen(value) {
    this.formReclamoGroup.controls.tipoRecp.setValue(value);
  }

  eliminaParametrosUrl() {
    this.router.navigate([], {
      queryParams: {
        identificacion: null,
        origen: null,
      },
      queryParamsHandling: "merge",
    });
  }

  retornarSufijoTipoDocumento(identificacion) {
    let sufijo = "";
    if (identificacion.length === 10) {
      sufijo = "C";
    } else if (identificacion.length === 13) {
      sufijo = "R";
    } else {
      sufijo = "P";
    }
    return sufijo;
  }

  obtenerParametros(url) {
    const params = {};
    const parser = document.createElement("a");
    parser.href = url;
    const query = parser.hash.substring(21);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split("=");
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
  }

  async bodyConsultaCliente(params) {
    const data: any = {
      Identificacion: "",
      TipoPersona: "",
    };
    this.bodyCliente = data;
    this.bodyCliente.Identificacion = params.identificacion;
  }
  /*Formulario ATC Recepcion de Parametros Por Url Ini */

  async sizeFile() {
    const en: any = await (
      await this.endPoint.getTokenApiGateway()
    ).toPromise();
    this.endPoint.setToken(en.token_type + " " + en.access_token);
    const data: any = {
      TokenId: "",
    };
    data.TokenId = en.access_token;
    const tamano: any = await this.coreService.httpArchivos(data).toPromise();
    this.tamanioArchivos = tamano[0].Valor;
    const res = tamano[0].Valor * 1000000;
    this.sizeGeneral = res;
  }

  mostrarCamposPorTipoPersona() {
    switch (sessionStorage.tipoPersona) {
      //let data = 'C'
      //switch (data) {
      case "C":
        // Se muestra la sección de datos para persona natural
        this.flagTipoPersona = true;
        // Se deshabilitan formControl para persona jurídica para no incluir validaciones
        this.formReclamoGroup.get("ruc").disable();
        this.formReclamoGroup.get("razonSocial").disable();

        break;
      case "P":
        // Se muestra la sección de datos para persona natural
        this.flagTipoPersona = true;
        // Se deshabilitan formControl para persona jurídica para no incluir validaciones
        this.formReclamoGroup.get("ruc").disable();
        this.formReclamoGroup.get("razonSocial").disable();

        break;
      case "R":
        // Se muestra la sección de datos para persona jurídica
        this.flagTipoPersona = false;
        // Se deshabilitan formControl para persona natural para no incluir validaciones
        //this.formReclamoGroup.get('tipoDocumento').disable();
        this.formReclamoGroup.get("identificacion").disable();
        this.formReclamoGroup.get("nombres").disable();
        this.formReclamoGroup.get("direccion").disable();
        this.formReclamoGroup.get("oficialDeCuenta").disable();
        this.formReclamoGroup.get("telefono1").disable();
        this.formReclamoGroup.get("telefono2").disable();
        this.formReclamoGroup.get("correo").disable();
        this.formReclamoGroup.get("lineaNegocio").disable();
        this.formReclamoGroup.get("provincia").disable();
        this.formReclamoGroup.get("ciudad").disable();
        //diisabe flechaNacimiento
        this.formReclamoGroup.get("fechaNacimiento").disable();
        //sisable edad
        this.formReclamoGroup.get("edad").disable();
        //disable genero
        this.formReclamoGroup.get("sexo").disable();
        break;
    }
  }

  llenarFormularioDatosCliente() {
    if (
      sessionStorage.tipoPersona === "C" ||
      sessionStorage.tipoPersona === "P"
    ) {
      this.formReclamoGroup
        .get("tipoDocumento")
        .setValue(sessionStorage.tipoPersona == "C" ? "Cedula" : "Pasaporte");
      this.formReclamoGroup
        .get("identificacion")
        .setValue(this.dataCliente.identificacionCliente);
      this.formReclamoGroup
        .get("nombres")
        .setValue(this.dataCliente.nombreCliente);
      this.formReclamoGroup
        .get("direccion")
        .setValue(this.dataCliente.direccion);
      this.formReclamoGroup
        .get("oficialDeCuenta")
        .setValue(this.dataCliente.nombreOficialCuenta);
      this.formReclamoGroup
        .get("telefono1")
        .setValue(this.dataCliente.telefono1);
      this.formReclamoGroup
        .get("telefono2")
        .setValue(this.dataCliente.telefono2);
      this.formReclamoGroup.get("correo").setValue(this.dataCliente.correo);
      this.formReclamoGroup
        .get("lineaNegocio")
        .setValue(this.dataCliente.lineaNegocio);
      this.formReclamoGroup
        .get("provincia")
        .setValue(this.dataCliente.provincia);
      this.formReclamoGroup.get("ciudad").setValue(this.dataCliente.ciudad);

      //setea fecha de nacimiento
      this.formReclamoGroup
        .get("fechaNacimiento")
        .setValue(this.dataCliente.fechaNacimiento);

      this.calcularEdad(this.dataCliente.fechaNacimiento);

      //sibcode

      this.formReclamoGroup.get("sibCode").setValue(this.dataCliente.sibCode);

      this.formReclamoGroup.get("sexo").setValue(this.dataCliente.sexo);
    } else {
      this.formReclamoGroup
        .get("tipoDocumento")
        .setValue(sessionStorage.tipoPersona == "R" ? "Ruc" : "");
      this.formReclamoGroup
        .get("ruc")
        .setValue(this.dataCliente.identificacionCliente);
      this.formReclamoGroup
        .get("razonSocial")
        .setValue(this.dataCliente.nombreCliente);
    }
  }

  async reqProdCliente() {
    const data: any = {
      Identificacion: "",
      Tipo: "",
      TokenId: "",
    };
    this.reqPCliente = data;
    this.reqPCliente.Identificacion = this.dataCliente.identificacionCliente;
    this.reqPCliente.Tipo = sessionStorage.tipoPersona;
  }

  async productoSeleccionado() {
    // Se resetean los campos de lista motivos y productos del cliente cuando cambia el producto
    this.formReclamoGroup.get("servicio").reset();
    this.formReclamoGroup.get("motivo").reset();
    const formArray: FormArray = this.formReclamoGroup.get(
      "camposAdicionales"
    ) as FormArray;
    formArray.clear();
    this.servicios = [];
    // Para llenar la lista de motivos
    const data: any = {
      Id: "",
      token: {
        TokenId: "",
      },
    };
    const idProducto = this.formReclamoGroup.get("producto").value.IdProducto;

    const descProducto =
      this.formReclamoGroup.get("producto").value.descripcion;
    if (descProducto == "QUEJAS") {
      this.formReclamoGroup.controls.montoReclamo.setValue(0);
      this.formReclamoGroup.controls.montoReclamo.disable();
    } else {
      this.formReclamoGroup.controls.montoReclamo.enable();

      /// validar que sea mayor a cero
    }

    data.Id = idProducto;
    const en: any = await (
      await this.endPoint.getTokenApiGateway()
    ).toPromise();
    this.endPoint.setToken(en.token_type + " " + en.access_token);
    data.token.TokenId = en.access_token;
    this.coreService.httpServicios(data).subscribe(
      (dataMotivosTemp) => {
        this.formReclamoGroup.controls.servicio.enable();
        this.formReclamoGroup.controls.motivo.enable();
        this.servicios = dataMotivosTemp;
        this.servicios = this.servicios.filter(
          (x) => x.IdProducto == idProducto
        );
      },
      (e) => {
        if (e.error.message) {
          this.alertaPSM("No se encontraron servicios para el producto;");
          this.formReclamoGroup.controls.servicio.disable();
          this.formReclamoGroup.controls.motivo.disable();
        }
      }
    );
  }

  async servicioSeleccionado() {
    this.formReclamoGroup.controls.montoReclamo.setValue("");

    //añadir clase que desactive el input monto
    const descProducto =
      this.formReclamoGroup.get("producto").value.descripcion;
    if (descProducto == "QUEJAS") {
      this.formReclamoGroup.controls.montoReclamo.setValue(0);
      this.formReclamoGroup.controls.montoReclamo.disable();
    } else {
      this.formReclamoGroup.controls.montoReclamo.enable();

      /// validar que sea mayor a cero
    }

    this.limpiarTransaccionesSeleccionadas();
    // Se resetean los campos de lista motivos y productos del cliente cuando cambia el producto
    this.formReclamoGroup.get("motivo").reset();
    const formArray: FormArray = this.formReclamoGroup.get(
      "camposAdicionales"
    ) as FormArray;
    formArray.clear();
    this.motivos = [];
    // Para llenar la lista de motivos
    const data: any = {
      Id: "",
      token: {
        TokenId: "",
      },
    };
    const idServicio = this.formReclamoGroup.get("servicio").value.IdServicio;
    data.Id = idServicio;
    const en: any = await (
      await this.endPoint.getTokenApiGateway()
    ).toPromise();

    this.endPoint.setToken(en.token_type + " " + en.access_token);
    data.token.TokenId = en.access_token;
    this.coreService.httpMotivos(data).subscribe(
      (dataMotivosTemp) => {
        this.formReclamoGroup.controls.motivo.enable();
        this.motivos = dataMotivosTemp;
        this.motivos = this.motivos.filter((x) => x.idServicio == idServicio);
      },
      (e) => {
        if (e.error) {
          this.motivos = [];
          this.alertaPSM("No se encontraron motivos para el producto;");
          this.formReclamoGroup.controls.motivo.disable();
        }
      }
    );

    this.obtenerParametroServicio();
    if (
      this.parametroEncontrado &&
      this.parametroEncontrado.IdConsumoServicio == 1
    ) {
      //asignar la fecha actual a fechaProblema
      let fechaActual = new Date();
      let localDate = formatDate(fechaActual, "yyyy-MM-dd", "en_US");
      this.formReclamoGroup.controls["fechaProblema"].setValue(localDate);
    }

    this.validarLlamadoTarjeta();
  }

  obtenerParametroServicio() {
    const { IdProducto } = this.formReclamoGroup.controls.producto.value;
    const idServiciof = this.formReclamoGroup.get("servicio").value.IdServicio;
    this.parametroEncontrado = this.parametroServicio.find((parametro) => {
      return (
        parametro.IdProducto === IdProducto &&
        parametro.IdServicio === idServiciof
      );
    });
  }
  validarLlamadoTarjeta() {
    const tipoCuenta = this.formReclamoGroup.controls.tipoCuenta.value;
    this.obtenerParametroServicio();
    //OBTENER UN PARAMETRO QUE COINCIDA IDPRODUCTO E IDSERVICIO

    if (
      !this.parametroEncontrado ||
      this.parametroEncontrado.IdConsumoServicio != 1
    ) {
      this.ocultarDetalles();
      this.ocultarFechas();

      this.tarjetaCredito = [];
      this.tarjetaTCSeleccionada = null;
      this.tarjetaDebito = [];
      this.tarjetaTBSeleccionada = null;

      //añadir clase que desactive el input monto
      const descProducto =
        this.formReclamoGroup.get("producto").value.descripcion;
      if (descProducto == "QUEJAS") {
        this.formReclamoGroup.controls.montoReclamo.setValue(0);
        this.formReclamoGroup.controls.montoReclamo.disable();
      } else {
        this.formReclamoGroup.controls.montoReclamo.enable();

        /// validar que sea mayor a cero
      }
      return;
    }

    if (!this.formReclamoGroup.controls.tipoCuenta.value) {
      this.alertaReusable("Tipo Cuenta", "Seleccione un tipo de cuenta");
      return;
    }
    this.formReclamoGroup.controls.montoReclamo.disable();

    this.fechasShow = true;
    this.tarjetaTDTCSeleccionada = null;
    if (
      tipoCuenta == "TRJ" &&
      this.parametroEncontrado.IdConsumoServicio == 1 &&
      this.cuentasTC.length >= 1
    ) {
      this.llamarTC();
    }

    if (
      tipoCuenta != "TRJ" &&
      this.parametroEncontrado.IdConsumoServicio == 1
    ) {
      this.llamarTD();
    }
  }

  async llamarTD() {
    var tipoDocumento = sessionStorage.tipoPersona;
    var identificacion = this.dataCliente.identificacionCliente;
    if (!this.cuentaSeleccionada) {
      this.alertaReusable("Cuenta", "Seleccione una cuenta");
      return;
    }

    const data: any = {
      identificacion: identificacion,
      tipoidentificacion: tipoDocumento,
      tipotarjeta: "TD",
      numerocuenta: this.cuentaSeleccionada.numerocuenta,
    };

    const en: any = await (
      await this.endPoint.getTokenApiGateway()
    ).toPromise();
    this.endPoint.setToken(en.token_type + " " + en.access_token);
    this.spinner.show();
    this.coreService.httpTarjetaDebito(data).subscribe(
      (data: any) => {
        if (data.codigoRespuesta == "0") {
          this.tarjetaDebito = data.tarjetaDebito;

          this.tarjetasTD = true;
        } else {
          this.alertaReusable(
            "Tarjeta TD",
            "No se encontraron tarjetas de débito"
          );
          this.tarjetaDebito = [];
          this.tarjetasTD = false;
        }
      },
      (error) => {
        this.alertaReusable(
          "Tarjeta TD",
          "Hubo un error al consultar las tarjetas de débito"
        );
        this.tarjetaDebito = [];
        this.tarjetasTD = false;
      },
      () => {
        this.spinner.hide();
      }
    );
  }
  async llamarTC() {
    var tipoDocumento = sessionStorage.tipoPersona;
    var identificacion = this.dataCliente.identificacionCliente;
    if (!this.cuentaSeleccionada) {
      this.alertaReusable("Cuenta", "Seleccione una cuenta");
      return;
    }

    const data: any = {
      identificacion: identificacion,
      tipoidentificacion: tipoDocumento,
      numerocuenta: this.cuentaSeleccionada.numerocuenta,
    };

    const en: any = await (
      await this.endPoint.getTokenApiGateway()
    ).toPromise();
    this.endPoint.setToken(en.token_type + " " + en.access_token);
    this.spinner.show();
    this.coreService.httpTarjetaCredito(data).subscribe(
      (data: any) => {
        this.tarjetaCredito = data.tarjetaCredito;
        if (this.tarjetaCredito.length == 0) {
          this.alertaReusable(
            "Tarjeta",
            "No se encontraron tarjetas de crédito"
          );
          this.tarjetasTC = false;
          return;
        }

        //todo descomentar
        /*  this.tarjetaCredito = this.tarjetaCredito.filter((tarjeta) =>
          this.estadosTC.some(
            (estado) => estado.SubStatusTc === tarjeta.estado.split("-")[0]
          )
        ); */

        if (this.tarjetaCredito.length == 0) {
          this.alertaReusable(
            "Tarjeta",
            "No se encontraron tarjetas de crédito con estados válidos"
          );
          this.tarjetasTC = false;
          return;
        }

        this.tarjetasTC = true;
      },
      (error) => {
        this.tarjetaCredito = [];
        this.tarjetasTC = false;
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  tarjetasTCBool: boolean[] = []; // Array de banderas para el estado de selección de cada tarjeta
  tarjetaSeleccionada: number = -1; // Índice de la tarjeta seleccionada
  //!esto si
  checkBoxTabla(event, i: number) {
    const checked = event.source._checked;

    if (checked) {
      // Si se selecciona una tarjeta, deseleccionar la anterior (si hay alguna)
      if (this.tarjetaSeleccionada !== -1) {
        this.tarjetasTCBool[this.tarjetaSeleccionada] = false;
      }

      this.tarjetaSeleccionada = i; // Actualizar la tarjeta seleccionada
      this.tarjetasTCBool[i] = true; // Marcar la tarjeta como seleccionada

      // Guardar el contenido de la tarjeta seleccionada en una variable
      this.tarjetaTDTCSeleccionada = this.tarjetaCredito[i];
    } else {
      // Si se deselecciona una tarjeta, actualizar el estado de selección y la variable
      this.tarjetaSeleccionada = -1;
      this.tarjetasTCBool[i] = false;
      this.tarjetaTDTCSeleccionada = null; // Limpiar la tarjeta seleccionada
    }
  }
  tarjetaSeleccionadaD: number = -1; // Índice de la tarjeta seleccionada
  checkBoxTablaDebito(event, i: number) {
    const checked = event.source._checked;

    if (checked) {
      // Si se selecciona una tarjeta, deseleccionar la anterior (si hay alguna)
      if (this.tarjetaSeleccionadaD !== -1) {
        this.tarjetasTCBool[this.tarjetaSeleccionadaD] = false;
      }

      this.tarjetaSeleccionadaD = i; // Actualizar la tarjeta seleccionada
      this.tarjetasTCBool[i] = true; // Marcar la tarjeta como seleccionada

      // Guardar el contenido de la tarjeta seleccionada en una variable
      this.tarjetaTDTCSeleccionada = this.tarjetaDebito[i];
    } else {
      // Si se deselecciona una tarjeta, actualizar el estado de selección y la variable
      this.tarjetaSeleccionadaD = -1;
      this.tarjetasTCBool[i] = false;
      this.tarjetaTDTCSeleccionada = null; // Limpiar la tarjeta seleccionada
    }
  }

  //!esto si
  aparecerBotonesTransacciones() {
    //si no tine una fechaDesde primero alerta que ingrese una fecha
    if (!this.formReclamoGroup.controls.fechaDesde.value) {
      this.alertaReusable("Fecha Desde", "Ingrese una fecha desde");
      //quitar valor de fecha hasta
      this.formReclamoGroup.controls.fechaHasta.setValue("");
      this.botonesTransacciones = false;
      return;
    }

    if (!this.formReclamoGroup.controls.fechaHasta.value) {
      this.alertaReusable("Fecha Hasta", "Ingrese una fecha hasta");
      this.botonesTransacciones = false;
      return;
    }

    const fromDate: Date = new Date(
      this.formReclamoGroup.controls.fechaDesde.value
    );
    const toDate: Date = new Date(
      this.formReclamoGroup.controls.fechaHasta.value
    );
    const diffDays: number = Math.floor(
      (toDate.getTime() - fromDate.getTime()) / (1000 * 60 * 60 * 24)
    );

    if (diffDays > 30) {
      alert("La diferencia entre las fechas no puede ser mayor a 30 días.");

      this.formReclamoGroup.controls.fechaHasta.setValue("");
      return;
    }

    this.botonesTransacciones = true;

    this.btnllaamarTransacciones("COMPENSADAS");
  }

  btnllaamarTransacciones(tipo: string) {
    this.paginaActual = 0;

    this.llamarTransacciones(tipo);
  }

  //!este si
  llamarTransacciones(tipo) {
    this.tipoTransaccion = tipo;
    //Si no tiene una tarjeta seleccionada, mostrar alerta
    if (!this.tarjetaTDTCSeleccionada) {
      this.alertaReusable("Tarjeta", "Seleccione una tarjeta");
      return;
    }

    if (this.tarjetaCredito.length > 0) {
      if (tipo == "MANUAL") {
        //ocultar paguinacion
      } else {
        this.llamarTransaccionesTC(tipo);
      }
    }

    if (this.tarjetaDebito.length > 0) {
      this.llamarTransaccionesTD(tipo);
    }
  }
  async llamarTransaccionesTC(tipo: string) {
    this.transacciones = [];
    if (tipo == "COMPENSADAS") {
      //obtener

      this.transaccionesPorPagina = this.parametrosTCTD.find(
        (parametro) => parametro.Descripcion == "Numero de pagina"
      ).Valor;

      var paginaenviar = this.paginaActual * this.transaccionesPorPagina;
      if (paginaenviar > this.totalTransacciones) {
        paginaenviar = this.totalTransacciones;
      }

      var fechaDesde = this.formReclamoGroup.controls.fechaDesde.value;
      var fechaHasta = this.formReclamoGroup.controls.fechaHasta.value;
      // Crear una instancia de DatePipe
      const datePipe = new DatePipe("en-US");

      // Formatear las fechas
      var fechaDesdeFormateada = datePipe.transform(fechaDesde, "MM/dd/yyyy");
      var fechaHastaFormateada = datePipe.transform(fechaHasta, "MM/dd/yyyy");
      var servicioDescripcion = this.formReclamoGroup.controls.servicio.value;
      //todo verificar

      var nemonico = this.nemonicos.find(function (item) {
        return (
          item.Descripcion.toUpperCase().trim() ===
          servicioDescripcion.Descripcion.toUpperCase().trim()
        );
      }).Valor;

      this.tarjeta = this.tarjetaTDTCSeleccionada.idTarjeta;
      const data: any = {
        numerocuenta: this.cuentaSeleccionada.numerocuenta,
        numerotarjeta: this.tarjetaTDTCSeleccionada.numerotarjeta,
        tipotransaccion: nemonico,
        fechadesde: fechaDesdeFormateada,
        fechahasta: fechaHastaFormateada,
        numeroregistro: this.transaccionesPorPagina,
        numeropagineo: paginaenviar,
        idTarjeta: this.tarjeta,
      };

      this.spinner.show();
      const en: any = await (
        await this.endPoint.getTokenApiGateway()
      ).toPromise();
      this.endPoint.setToken(en.token_type + " " + en.access_token);

      this.coreService.httpTransaccionesCompensadasTC(data).subscribe(
        (data: any) => {
          this.transacciones = data.transaccionesTarjetaCredito;
          this.totalTransacciones = data.cantidadTransacciones;

          this.detalleTransCuentas = true;

          this.transacciones.forEach((tr) => console.log(tr));
        },
        (error) => {
          this.transacciones = [];
          this.detalleTransCuentas = false;
        },
        () => {
          this.spinner.hide();
          return;
        }
      );
    }

    if (tipo == "AUTORIZACION") {
    }
  }
  async llamarTransaccionesTD(tipo: string) {
    this.transacciones = [];
    if (tipo == "COMPENSADAS") {
      var fechaDesde = this.formReclamoGroup.controls.fechaDesde.value;
      var fechaHasta = this.formReclamoGroup.controls.fechaHasta.value;
      // Crear una instancia de DatePipe
      const datePipe = new DatePipe("en-US");

      // Formatear las fechas
      var fechaDesdeFormateada = datePipe.transform(fechaDesde, "MM/dd/yyyy");
      var fechaHastaFormateada = datePipe.transform(fechaHasta, "MM/dd/yyyy");

      var servicioDescripcion = this.formReclamoGroup.controls.servicio.value;
      //todo verificar
      this.transaccionesPorPagina = this.parametrosTCTD.find(
        (parametro) => parametro.Descripcion == "Numero de pagina"
      ).Valor;

      var paginaenviar = this.paginaActual * this.transaccionesPorPagina;
      if (paginaenviar > this.totalTransacciones) {
        paginaenviar = this.totalTransacciones;
      }

      var nemonico = this.nemonicos.find(function (item) {
        return (
          item.Descripcion.toUpperCase().trim() ===
          servicioDescripcion.Descripcion.toUpperCase().trim()
        );
      }).Valor;
      this.tarjeta = this.tarjetaTDTCSeleccionada.codigotarjeta;
      const data: any = {
        idTarjeta: this.tarjetaTDTCSeleccionada.codigotarjeta,
        numerotarjeta: this.tarjetaTDTCSeleccionada.numerotarjeta,
        tipotransaccion: nemonico,
        fechadesde: fechaDesdeFormateada,
        fechahasta: fechaHastaFormateada,

        numeroregistro: this.transaccionesPorPagina,
        numeropagineo: paginaenviar,
      };

      this.spinner.show();
      const en: any = await (
        await this.endPoint.getTokenApiGateway()
      ).toPromise();
      this.endPoint.setToken(en.token_type + " " + en.access_token);

      this.coreService.httpTransaccionesCompensadasTD(data).subscribe(
        (data: any) => {
          this.transacciones = data.transaccionesTarjetaDebito;

          this.totalTransacciones = data.cantidadTransacciones;

          this.detalleTransCuentas = true;

          this.transacciones.forEach((tr) => console.log(tr));
        },
        (error) => {
          this.transacciones = [];
          this.detalleTransCuentas = false;
        },
        () => {
          this.spinner.hide();
          return;
        }
      );
    }

    if (tipo == "AUTORIZACION") {
    }
  }

  cambiarPagina(numeroPagina: number) {
    this.paginaActual = numeroPagina - 1;
    this.llamarTransacciones(this.tipoTransaccion);
  }

  transaccionSeleccionadaIndex: number = -1;

  actualizarTransaccionesSeleccionadas(event: any, index: number) {
    if (event.checked) {
      // Si se selecciona una fila, guarda el índice de la transacción seleccionada
      this.transaccionSeleccionadaIndex = index;
      // Actualiza la transacción seleccionada en el arreglo
      this.transaccionesSeleccionadas.transaccionesSeleccionadasTDTC = [
        event.source.value,
      ];
    } else {
      // Si se deselecciona una fila, establece el índice de la transacción seleccionada a -1
      this.transaccionSeleccionadaIndex = -1;
      this.transaccionesSeleccionadas.transaccionesSeleccionadasTDTC = null;
    }

    const valorReclamado =
      this.transaccionesSeleccionadas.transaccionesSeleccionadasTDTC[0].valordelatransaccion.replace(
        /,/g,
        ""
      );
    this.formReclamoGroup.controls.montoReclamo.setValue(valorReclamado);
  }

  async seleccionCiudades() {
    this.agencias = [];
    const data: any = {
      Id: 901,
      token: {
        TokenId: "",
      },
    };

    const en: any = await (
      await this.endPoint.getTokenApiGateway()
    ).toPromise();
    this.endPoint.setToken(en.token_type + " " + en.access_token);
    data.token.TokenId = en.access_token;
    const dataAgenciasTemp: any = await this.coreService
      .httpAgencias(data)
      .toPromise();
    this.agencias = dataAgenciasTemp;
    var agenciaCodigo0 = this.agencias.find(function (item) {
      return item.Codigo === "0";
    });
    this.formReclamoGroup.controls.oficinaIngreso.setValue(
      agenciaCodigo0.Nombre
    );
  }

  async seleccionTipoCuenta() {
    this.cuentaSeleccionada = null;
    this.tipocuentades = this.formReclamoGroup.controls.tipoCuenta.value;

    this.tipocuentades = this.tipoCuenta.find(
      (x) => x.id == this.tipocuentades
    ).valor;

    console.log("tipo cuenta des", this.tipocuentades);
    //quitar disabled de producto
    this.formReclamoGroup.controls.producto.enable();
    this.formReclamoGroup.controls.producto.setValue("");
    this.productosCliente = [];
    this.valdidarLLamadoQ01();
    this.flagTableCuentasTC = false;
    this.formReclamoGroup.get("producto").setValue("");
    this.formReclamoGroup.get("servicio").setValue("");
    this.tituloGrid = "";
    const tipoC = this.formReclamoGroup.controls.tipoCuenta.value;
    this.tituloGrid = tipoC !== "TRJ" ? "Cuenta" : "Tarjeta";

    if (tipoC != "TRJ") {
      this.ocultarSegundoItem = true;
    } else {
      this.ocultarSegundoItem = false;
    }
    // Para llenar la tabla de productos del cliente
    /*await this.reqProdCliente();
     const en: any = await (
      await this.endPoint.getTokenApiGateway()
    ).toPromise();
    this.endPoint.setToken(en.token_type + ' ' + en.access_token);
    this.reqPCliente.TokenId = en.access_token;
    const dataServicioTemp: any = await this.coreService.httpProductosCliente(this.reqPCliente).toPromise();*/
    //const tipoCuenta = this.tipoCuenta.find(x => x.id == tipoC);
    let productClient;
    const data: any = [
      {
        NumeroCuenta: "",
        Descripcion: "",
      },
    ];

    if (tipoC != "TRJ") {
      for (let i = 0; i < this.cuentasCliente.length; i++) {
        if (this.cuentasCliente[i].Tipo == tipoC) {
          productClient = this.cuentasCliente[i].Cuentas.filter(
            (x) => x.Tipo == tipoC
          );
          this.contador = 0;
          this.productosCliente = productClient;
          this.flagTable = true;
        }
      }

      if (this.flagTable === false) {
        this.contador = 0;
        this.alertaCuenta("Usted no dispone de este producto.");
      }
    } else {
      this.flagTable = false;

      this.llamarCuentasTc();
      /* 
      var valueBankard = this.productos.find(
        (element) => element.IdProducto === 2
      );
      this.formReclamoGroup.controls.producto.disable();
      this.formReclamoGroup.controls.producto.setValue(valueBankard); */
      this.productoSeleccionado();
    }

    /*for (let i = 0; i < dataServicioTemp.length; i++) {
      if (dataServicioTemp[i].Tipo == tipoCuenta.valor) {
        productClient = dataServicioTemp[i].Cuentas.filter(x => x.Tipo == tipoCuenta.valor);
        this.contador = 0;
        this.productosCliente = productClient;
        this.flagTable = true;
        return false;
      }
    }*/
  }

  async llamarCuentasTc() {
    this.valdidarLLamadoQ01();

    var tipoDocumento = sessionStorage.tipoPersona;
    var identificacion = this.dataCliente.identificacionCliente;

    const data: any = {
      identificacion: identificacion,
      tipoidentificacion: tipoDocumento,
    };

    const en: any = await (
      await this.endPoint.getTokenApiGateway()
    ).toPromise();
    this.endPoint.setToken(en.token_type + " " + en.access_token);

    this.spinner.show();
    this.coreService.httpCuentasTC(data).subscribe(
      (data: any) => {
        this.cuentasTC = data.cuentasTarjetaCredito;

        this.flagTableCuentasTC = true;

        //si solo hay una cuenta, seleccionarla, sino añadir un seleccione

        if (this.cuentasTC.length == 1) {
          this.numeroCuentaSeleccionada = String(
            this.cuentasTC[0].numerocuenta
          );
          this.cuentaSeleccionada = this.cuentasTC[0];
        }
      },
      (error) => {
        this.cuentasTC = [];
        this.flagTableCuentasTC = false;
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  valdidarLLamadoQ01() {
    this.formReclamoGroup.controls.montoReclamo.setValue("");

    this.cuentasTC = [];
    this.numeroCuentaSeleccionada = null;
    this.tarjetaCredito = [];
    this.tarjetaSeleccionadaD = null;
    this.tarjetaDebito = [];
    this.tarjetaSeleccionada = null;

    this.ocultarFechas();
    this.limpiarTransaccionesSeleccionadas();
    this.ocultarDetalles();
  }

  changeCuentasTC(event) {
    this.tarjetasTC = false;
    this.tarjetaCredito = [];
    this.tarjetaSeleccionadaD = null;
    this.tarjetaDebito = [];
    this.tarjetaSeleccionada = null;

    this.ocultarFechas();
    this.limpiarTransaccionesSeleccionadas();
    this.ocultarDetalles();

    var producto = this.cuentasTC.find(
      (element) => element.numerocuenta === event
    );

    this.cuentaSeleccionada = producto;

    this.validarLlamadoTarjeta();
  }

  limpiarTransaccionesSeleccionadas() {
    this.transaccionesSeleccionadas.transaccionesSeleccionadasCheque = [];
    this.transaccionesSeleccionadas.transaccionesSeleccionadasATM = [];
    this.transaccionesSeleccionadas.transaccionesSeleccionadasTDTC = [];
  }
  ocultarDetalles() {
    /*   $("#detalleTransCuentas").hide();
    $("#paginacion").hide();
    $("#btnLlamado").hide();
    $("#btnAutorizacion").hide();
    $("#btnCompensadas").hide();
    $("#btnIngresoManual").hide();
    $("#tarjetasTC").hide();
    $("#ingresoManualTable").hide(); */
    this.detalleTransCuentas = false;
    this.paginacion = false;
    this.btnLlamado = false;
    //   this.btnAutorizacion = false;
    //  this.btnCompensadas = false;
    // this.btnIngresoManual = false;
    this.botonesTransacciones = false;
    this.tarjetasTC = false;
    this.tarjetasTD = false;
    // this.flagTableCuentasTC = false;
    this.ingresoManualTable = false;

    this.transacciones = null;
  }
  ocultarFechas() {
    /*  $("#divFechaDesde").hide();
    $("#divFechaHasta").hide();
    $("#fechaDesde").val("");
    $("#fechaHasta").val(""); */
    this.fechasShow = false;
    this.formReclamoGroup.controls.fechaDesde.setValue("");
    this.formReclamoGroup.controls.fechaHasta.setValue("");
  }
  alertaReusable(name: string, msg: string) {
    var dialog3 = this.dialog.open(DialogAlertComponent, {
      data: {
        name: name,
        content: msg,
        buttons: false,
        btnAceptar: false,
        btnCancelar: true,
      },
    });
    return dialog3;
  }
  async reclamoSeleccionado() {
    this.flagCampos = false;
    const data: any = {
      Id: "",
      token: {
        TokenId: "",
      },
    };
    const tipoReclamo = this.formReclamoGroup.controls.motivo.value.idMotivos;
    data.Id = tipoReclamo;

    if (
      this.parametroEncontrado &&
      this.parametroEncontrado.IdConsumoServicio == 1
    ) {
      data.Id = 160;
    }

    const en: any = await (
      await this.endPoint.getTokenApiGateway()
    ).toPromise();
    this.endPoint.setToken(en.token_type + " " + en.access_token);
    data.token.TokenId = en.access_token;
    this.coreService.httpCamposAdicionales(data).subscribe(
      (data) => {
        this.dataCamposAdicionales = data;
        if (
          this.parametroEncontrado &&
          this.parametroEncontrado.IdConsumoServicio == 1
        ) {
          var campos = [1, 2, 4, 5];
          //solo deja en this.dataCamposAdicionales.Campos los campos cuya campo.Codigo que se encuentran en  campos
          this.dataCamposAdicionales.Campos =
            this.dataCamposAdicionales.Campos.filter((campo) =>
              campos.includes(campo.Codigo)
            );
        }
        this.dataCajero = this.dataCamposAdicionales.Cajeros;
        this.generateForm(this.dataCamposAdicionales.Campos);

        let identificacion = "";
        let correo = "";
        if (this.dataCliente !== null || this.dataCliente !== undefined) {
          correo = this.dataCliente.correo;
          identificacion = this.dataCliente.telefono1;
        }

        let contador = 0;
        (<FormArray>(
          this.formReclamoGroup.get("camposAdicionales")
        )).controls.forEach((formGroup: FormGroup) => {
          (<any>Object)
            .values(formGroup.controls)
            .forEach((control: FormControl) => {
              let type = this.dataCamposAdicionales.Campos[contador].DescTipo;
              const key: any = Object.keys(formGroup.controls);
              if (type == "EMAIL") {
                formGroup.controls[key].setValue(correo);
              }
              if (type == "CELULAR") {
                formGroup.controls[key].setValue(identificacion);
              }
              contador = contador + 1;
            });
        });
      },
      (e) => {
        this.dataCamposAdicionales = [];
        const formArray: FormArray = this.formReclamoGroup.get(
          "camposAdicionales"
        ) as FormArray;
        formArray.clear();
        this.alertaPSM("No existen campos adicionales");
      }
    );
  }

  get camposAdicionales(): FormGroup {
    return this.formReclamoGroup.get("camposAdicionales") as FormGroup;
  }

  private generateForm(data: any[]) {
    const formArray: FormArray = this.formReclamoGroup.get(
      "camposAdicionales"
    ) as FormArray;
    formArray.clear();
    data.map((dt) => {
      formArray.push(this.buildFormDynamic(dt));
    });
    this.flagCampos = true;
  }

  private buildFormDynamic(data: any): FormGroup {
    if (!data) {
      return;
    }

    if (!data.hasOwnProperty("Emonico")) {
      return;
    }

    const valueControl = (input: any) => {
      if (!input.Value) {
        if (input.DescTipo === "CARACTER") {
          return "";
        } else if (input.DescTipo === "EMAIL") {
          return "";
        } else if (input.DescTipo === "SELECCION") {
          return "";
        } else if (input.DescTipo === "DATE") {
          return "";
        } else if (input.DescTipo === "NUMERICO") {
          return;
        } else if (input.DescTipo === "CELULAR") {
          return;
        }
      }
      return input.Value;
    };

    const customValidatePattern = (config: any): ValidatorFn => {
      return (control: FormControl) => {
        let urlExReg: RegExp = config.pattern;
        if (config.msg === "NO APLICA") {
          //KBastida Validacion para invalidar expresion regular, validacion por componente
          return null;
        }

        if (control.value && !control.value.match(urlExReg)) {
          return {
            invalidateInput: config.msg,
          };
        } else {
          return null;
        }
      };
    };

    const typeControl = (input: any): any => {
      if (input.DescTipo == "CARACTER") {
        this.msErrorNum = "* Ingrese solo letras y números";
        return {
          pattern: /^[a-zA-Z0-9 ]+$/,
          msg: "* Ingrese solo letras y números",
        };
      } else if (input.DescTipo == "EMAIL") {
        this.msErrorNum = "* Ingrese un correo válido";
        return {
          pattern:
            "^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$",
          msg: "* Ingrese un correo válido",
        };
      } else if (
        input.DescTipo == "NUMERICO" ||
        input.DescTipo == "SELECCION"
      ) {
        return { pattern: /^[0-9]*$/, msg: "* Ingrese solo números" };
      } else if (input.DescTipo == "DATE") {
        return {
          pattern: /^[a-zA-Z0-9 -/.]+$/,
          msg: "* Ingrese solo letras y números",
        };
      } else if (input.DescTipo == "DECIMAL") {
        return { pattern: /^[]+$/, msg: "NO APLICA" };
      } else if (input.DescTipo == "CELULAR") {
        return { pattern: "09+[0-9]{8}", msg: "* Ingrese un número válido" };
      }
    };

    const control = {};

    const validations = [];
    if (data.Obligatorio === "S") {
      validations.push(Validators.required);
    }

    validations.push(customValidatePattern(typeControl(data)));

    control[data.Emonico] = [
      valueControl(data),
      Validators.compose(validations),
    ];

    return this.formBuilder.group(control);
  }

  parseDynamicForm(): any {
    const formValue = this.camposAdicionales.value;
    for (let i = 0; i < formValue.length; i++) {
      const data = Object.entries(formValue[i]);
      for (let j = 0; j < this.dataCamposAdicionales.Campos.length; j++) {
        if (this.dataCamposAdicionales.Campos[j].Emonico == data[0][0]) {
          if (this.dataCamposAdicionales.Campos[j].Valor !== "") {
            this.dataCamposAdicionales.Campos[j].Valor = (<HTMLInputElement>(
              document.getElementById(data[0][0])
            )).value
              .toString()
              .replace(this.regex, "");
          } else {
            this.dataCamposAdicionales.Campos[j].Valor = (<HTMLInputElement>(
              document.getElementById(data[0][0])
            )).value.replace(this.regex, "");
          }
        }
      }
    }

    if (this.dataCamposAdicionales === undefined) {
      return null;
    }
    return this.dataCamposAdicionales.Campos;
  }

  onFileClear(event, ide) {
    debugger;
    switch (ide) {
      case 1:
        this.docCed = false;
        this.formReclamoGroup.get("doc1").setValue("");
        this.cantidadArchivoCargado = 0;
        this.almacenarArchivos = [];
        break;
      case 2:
        this.formReclamoGroup.get("doc2").setValue("");
        this.docTrx = false;
        break;
      case 3:
        this.formReclamoGroup.get("doc3").setValue("");
        this.docCarta = false;
        break;
      case 4:
        this.formReclamoGroup.get("doc4").setValue("");
        this.docDenuncia = false;
        break;
      default:
        break;
    }
  }

  onFileChange(event, id: number) {
    const file = event.target.files;
    if (this.isValidExtension(file[0].name.split(".").pop())) {
      const fl = file[0];
      if (fl.size > this.sizeGeneral) {
        const res = this.sizeGeneral / 1000000;
        event.target.value = "";
        this.alertaValidacion(
          "El tamaño máximo permitido es de " + res + "Mb."
        );
      } else {
        switch (id) {
          case 1:
            this.docCed = true;
            this.file1 = file;
            this.guardarMultipleArchivos();
            break;
          case 2:
            this.docTrx = true;
            this.file2 = file;
            break;
          case 3:
            this.docCarta = true;
            this.file3 = file;
            break;
          case 4:
            this.docDenuncia = true;
            this.file4 = file;
            break;
          default:
            break;
        }
      }
    } else {
      event.target.value = "";
      this.alertaValidacion(
        "Formato de archivo no permitido. Extensiones permitidas: .JPEG .PNG .JPG .BMP .TIFF .HEIC .PDF .DOCX .DOC .WEBP .TXT"
      );
    }
  }

  async guardarMultipleArchivos() {
    this.contArchivos = this.contArchivos + 1;
    this.spinner.show();
    if (this.contArchivos <= this.dataConfig["maxArchivo"]) {
      if (this.file1.length > 0) {
        const postData = {
          idReclamo: 0,
          isFormulario: "",
          FileName: "",
          FileBase: "",
          token: {
            TokenId: "",
          },
        };
        let data: any;
        const formdata1 = new FileReader();
        setTimeout(() => {
          formdata1.readAsDataURL(this.file1[0]);
          formdata1.onload = () => {
            data = formdata1.result.toString().replace(/^data:(.*,)?/, "");
            if (data.length % 4 > 0) {
              data += "=".repeat(4 - (data.length % 4));
            }
            postData.idReclamo = Number(this.idReclamo);
            postData.isFormulario = "false";
            postData.FileBase = data;
            var fileExtension = "." + this.file1[0].name.split(".").pop();
            //postData.FileName = 'Otros Documentos' + fileExtension;
            postData.FileName =
              this.file1[0].name.split(".")[0] + fileExtension;
            this.almacenarArchivos.push(postData);
            this.cantidadArchivoCargado = this.contArchivos;
          };
        }, 500);
      }
    } else {
      this.alertaValidacion(
        "Cantidad de Archivos Permitidos  " + this.dataConfig["maxArchivo"]
      );
    }
    this.spinner.hide();
  }

  async onChangeDocFirmado(event) {
    const file = event.target.files;
    if (this.isValidExtension(file[0].name.split(".").pop())) {
      this.docFirmado = file;
    } else {
      event.target.value = "";
      this.alertaValidacion(
        "Formato de archivo no permitido. Extensiones permitidas: .JPEG .PNG .JPG .BMP .TIFF .HEIC .PDF .DOCX .DOC .WEBP .TXT"
      );
    }
  }

  isValidExtension(extension: string) {
    return (
      extension.toUpperCase() === "JPEG" ||
      extension.toUpperCase() === "PNG" ||
      extension.toUpperCase() === "JPG" ||
      extension.toUpperCase() === "BMP" ||
      extension.toUpperCase() === "TIFF" ||
      extension.toUpperCase() === "HEIC" ||
      extension.toUpperCase() === "PDF" ||
      extension.toUpperCase() === "DOCX" ||
      extension.toUpperCase() === "DOC" ||
      extension.toUpperCase() === "WEBP" ||
      extension.toUpperCase() === "TXT"
    );
  }

  quitarParpadeo() {
    this.parpadeo = "card card-background";
  }

  seleccionTipoDoc() {
    // Cambia la validación del input identificación de acuerdo al tipo de documento
    switch (this.formReclamoGroup.get("tipoDocumento").value.valor) {
      case "Cédula":
        this.length = "10";
        this.isNumber = true;
        this.formReclamoGroup.get("identificacion").reset();
        this.formReclamoGroup
          .get("identificacion")
          .setValidators([
            Validators.required,
            Validators.minLength(10),
            Validators.pattern(/^[0-9]*$/),
          ]);
        break;
      case "Ruc":
        this.length = "13";
        this.isNumber = true;
        this.formReclamoGroup.get("identificacion").reset();
        this.formReclamoGroup
          .get("identificacion")
          .setValidators([
            Validators.required,
            Validators.minLength(13),
            Validators.pattern(/^[0-9]*$/),
          ]);
        break;
      case "Pasaporte":
        this.length = "8";
        this.isNumber = false;
        this.formReclamoGroup.get("identificacion").reset();
        this.formReclamoGroup
          .get("identificacion")
          .setValidators([
            Validators.required,
            Validators.minLength(8),
            Validators.pattern(/^[A-Za-z0-9]*$/),
          ]);
        break;
      default:
        break;
    }
  }

  checkBoxTablaProductos(numeroCuenta: any) {
    this.tarjetasTD = false;
    this.tarjetaCredito = [];
    this.tarjetaSeleccionadaD = null;
    this.tarjetaDebito = [];
    this.tarjetaSeleccionada = null;

    this.ocultarFechas();
    this.limpiarTransaccionesSeleccionadas();
    this.ocultarDetalles();
    //no aparecer el producto bancarrk de la lista de productos                 $("#idProducto option[value='2']").css('display', 'none');
    // Esta función valida que el usuario solo pueda seleccionar un producto a la vez
    /*  const checked = event.source._checked;
    this.productosCliente[i].seleccionar = checked;
    this.chekcuenta = true; */

    //!esto creo que si
    //reccorre productosCliente y obten el que coincida con el numero de cuenta y en seleccionar ponlo en false
    var producto = this.productosCliente.find(
      (element) => element.NumeroCuenta === numeroCuenta
    );
    this.cuentaSeleccionada = producto;

    //reccorre productosCliente y  el que coincida con el numero de cuenta ,  en seleccionar ponlo en true y los demas en false
    this.productosCliente.forEach((element) => {
      if (element.NumeroCuenta === numeroCuenta) {
        element.seleccionar = true;
      } else {
        element.seleccionar = false;
      }
    });

    this.cuentaSeleccionada.numerocuenta = this.cuentaSeleccionada.CuentaNumero;
    //todo validar el idTarjeta para enviarlo en el reclamo

    this.chekcuenta = true;

    this.validarLlamadoTarjeta();
  }

  //scroll-boton guardar formulario campos adicionales no se desplace hasta abajo
  public moveScrollUp() {
    window.scroll(0, 0);
    window.parent.scroll(0, 0);
    window.setTimeout(function () {
      window.scrollTo(0, 0);
    }, 0);
  }

  async guardarFormulario() {
    if (this.transacciones != null) {
      if (
        (this.transacciones.length > 0 &&
          this.transaccionesSeleccionadas.transaccionesSeleccionadasTDTC ==
            null) ||
        this.transaccionesSeleccionadas.transaccionesSeleccionadasTDTC.length ==
          0
      ) {
        this.alertaValidacion("Seleccione una transacción");
        return;
      }
    }

    this.error = [];
    const controls = this.formReclamoGroup.controls;
    let fechaProblema = this.formReclamoGroup.get("fechaProblema").value;
    let fechaActual = new Date();
    //iro-04102022
    let fechaMim = new Date("2000-01-02");
    let localDatemin = formatDate(fechaMim, "yyyy-MM-dd", "en_US");
    let localDate = formatDate(fechaActual, "yyyy-MM-dd", "en_US");

    let requerido = false;
    let requeridoAd = false;
    let requeridoAdfin = false;
    let campoRequerido = "";
    let campoReq = "";
    let campoReqAd = "";
    let invalidControl;

    // valida los campos requeridos
    for (const key of Object.keys(this.formReclamoGroup.controls)) {
      campoRequerido = key;

      switch (campoRequerido) {
        case "montoReclamo":
          campoReq = "Valor Reclamado";
          break;

        case "fechaProblema":
          campoReq = "Fecha del Problema";
          break;

        case "motivo":
          campoReq = "Tipo de Reclamo";
          break;

        case "servicio":
          campoReq = "Servicio";
          break;

        case "producto":
          campoReq = "Producto";
          break;

        case "descHechos":
          campoReq = "Descripción de los Hechos";
          break;

        case "peticion":
          campoReq = "Petición en Concreto";
          break;

        case "tipoCuenta":
          campoReq = "Tipo de cuenta";
          break;

        default:
          campoReq = key;
          break;
      }

      if (this.formReclamoGroup.controls["tipoCuenta"].valid) {
        // toma la cuenta selecionan
        /*    for (const iterator of this.productosCliente) {
          if (iterator.seleccionar) {
            this.codOperacion = this.cuentaSeleccionada;
            break;
          }
        } */
        if (this.cuentaSeleccionada != null) {
          this.codOperacion = this.cuentaSeleccionada.numerocuenta;
        }

        if (!this.chekcuenta) {
          this.codOperacion = "";
        }

        if (this.codOperacion === "") {
          this.chekcuenta = false;
          // invalidControl = document.getElementById(campoReqAd);
          const invalidControl = document.getElementById("id_numeroCuenta");
          //this.el.nativeElement.querySelector('[formcontrolname=NumeroCuenta"]');
          //const invalidControl = this.el.nativeElement.querySelector('[formcontrolname=NumeroCuenta"]');
          if (invalidControl != null) {
            var dialogo = this.alertaCampoReqRec(
              "Debe seleccionar Numero de Cuenta/Tarjeta.",
              "Numero Cuenta/Tarjeta"
            );
            dialogo.afterClosed().subscribe((res) => {
              invalidControl.focus();
            });
            requerido = true;
          }
        }

        //if
      }

      if (this.formReclamoGroup.controls[key].invalid) {
        invalidControl = this.el.nativeElement.querySelector(
          '[formcontrolname="' + key + '"]'
        );
        if (invalidControl != null) {
          var dialogRef = this.alertaCampoReqRec(
            "El campo (" + campoReq + ") no debe estar vacio.",
            campoReq
          );
          dialogRef.afterClosed().subscribe((res) => {
            invalidControl.focus();
          });
          requerido = true;
        }
      } else {
        if (campoRequerido == "montoReclamo") {
          invalidControl = this.el.nativeElement.querySelector(
            '[formcontrolname="' + campoRequerido + '"]'
          );
          let campoR = this.formReclamoGroup.get("montoReclamo").value;
          if (campoR == null) {
            var dialogRef = this.alertaCampoReqRec(
              "El campo (" + campoReq + ") no debe estar vacio.",
              campoReq
            );
            dialogRef.afterClosed().subscribe((res) => {
              invalidControl.focus();
            });

            requerido = true;
          }
        }

        //else
      }

      if (requerido) {
        break;
      }
    }

    // Focus
    if (requerido) {
      invalidControl.focus();
    }

    //mark As Touched
    /*  if (this.formReclamoGroup.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      
    }*/

    // campos Adicionales
    if (this.formReclamoGroup.invalid && !requerido) {
      if (this.formReclamoGroup.controls.camposAdicionales.invalid) {
        try {
          //  campos adicionales
          for (const key of Object.keys(
            (<FormArray>this.formReclamoGroup.controls.camposAdicionales)
              .controls
          )) {
            // campos adicionales
            if (
              (<FormArray>this.formReclamoGroup.controls.camposAdicionales)
                .controls[key].invalid
            ) {
              let nombreKey = (<FormArray>(
                this.formReclamoGroup.controls.camposAdicionales
              )).controls[key].value;
              let nomCampo = Object.keys(nombreKey)[0];
              campoReqAd = nomCampo;
              nomCampo = this.retornaNombreCampo(campoReqAd);

              /*for (const  campo of Object.keys(nombreKey)) {
                campoReqAd = campo;
                nomCampo = this.retornaNombreCampo(campo);
               
              }*/

              invalidControl = document.getElementById(campoReqAd);

              //this.el.nativeElement.camposAdicionales.querySelector('[formcontrolname="' + campo + '"]');

              var dialogRef = this.alertaCampoReqRec(
                "El campo (" + nomCampo + ") no debe estar vacio.",
                nomCampo
              );
              dialogRef.afterClosed().subscribe((res) => {
                invalidControl.focus();
                this.moveScrollUp();
              });

              requeridoAd = true;
            }

            if (requeridoAd) {
              break;
            }
          }
        } catch (error) {}
      }
    }

    // focus de campos adicionales
    if (requeridoAd) {
      invalidControl.focus();
    }
    //alerta Final Ingreso
    if (
      this.formReclamoGroup.controls.camposAdicionales.value.length > 0 &&
      this.formReclamoGroup.controls.camposAdicionales.valid &&
      !requeridoAd
    ) {
      // valida fecha de problema no sea mayor a la fecha actuañ
      if (fechaProblema > localDate) {
        this.formReclamoGroup.controls.fechaProblema.setValue(null);
        if (this.formReclamoGroup.invalid) {
          const invalidControl = this.el.nativeElement.querySelector(
            '[formcontrolname="fechaProblema"]'
          );
          var dialogoAlerta = this.alertaValidacion(
            "La fecha de Problema no puede ser mayor a la Fecha actual"
          );
          dialogoAlerta.afterClosed().subscribe((res) => {
            invalidControl.focus();
          });
        }
      }

      if (!this.cuentaSeleccionada) {
        this.alertaReusable("Cuenta", "Seleccione una cuenta");
        return;
      }

      //iro-04102022 valida fecha de problema no sea mayor a la fecha actuañ
      if (fechaProblema < localDatemin) {
        this.formReclamoGroup.controls.fechaProblema.setValue(null);
        if (this.formReclamoGroup.invalid) {
          const invalidControl = this.el.nativeElement.querySelector(
            '[formcontrolname="fechaProblema"]'
          );
          var dialofecha = this.alertaValidacion(
            "La fecha de Problema no puede ser menor a la fecha seleccionada"
          );
          dialofecha.afterClosed().subscribe((res) => {
            invalidControl.focus();
          });
        }
      }

      //validar seleccion de numero de cuenta
      // valida monto de reclamo
      let descProdQuejas =
        this.formReclamoGroup.get("producto").value.descripcion;
      if (descProdQuejas != "QUEJAS") {
        if (
          this.formReclamoGroup.get("montoReclamo").value == 0 ||
          this.formReclamoGroup.get("montoReclamo").value == null
        ) {
          const invalidControl = this.el.nativeElement.querySelector(
            '[formcontrolname="montoReclamo"]'
          );
          var dialogoReclamo = this.alertaValorReclamo(
            "El valor del reclamo debe ser mayor a cero."
          );

          dialogoReclamo.afterClosed().subscribe((res) => {
            invalidControl.focus();
          });

          requeridoAdfin = false;
        } else {
          requeridoAdfin = true;
        }
      } else {
        requeridoAdfin = true;
      }

      if (requeridoAdfin) {
        this.parseDynamicForm();
        await this.llenarBodyConCamposDeFormulario();
        // Envío de body al Servicio Web
        this.spinner.show();
        const en: any = await (
          await this.endPoint.getTokenApiGateway()
        ).toPromise();
        this.endPoint.setToken(en.token_type + " " + en.access_token);
        this.body.token.TokenId = en.access_token;
        console.log(this.body);
        this.coreService.httpIngresoReclamo(this.body).subscribe(
          async (dataServicioTemp: any) => {
            const dataServicio = dataServicioTemp;
            const res = dataServicio.Mensaje.split(":");
            const res2 = res[1].split("&");
            this.formReclamoGroup.get("reclamo").setValue(res2[0]);
            this.idReclamo = res2[1];
            this.spinner.hide();

            if (
              this.transaccionesSeleccionadas.transaccionesSeleccionadasTDTC
                .length > 0
            ) {
              await this.guardarDetalleReclamo(this.idReclamo);
            }

            await this.generarPdf();
            setTimeout(() => {
              this.subirArchivo();
              setTimeout(() => {
                if (this.error.length !== 0) {
                  for (let i = 0; i < this.error.length; i++) {
                    if (this.error[i] == 400) {
                      this.formReclamoGroup.get("reclamo").setValue(null);
                      this.contArchivos = 0;
                      this.cantidadArchivoCargado = 0;
                    } else {
                      this.formReclamoGroup.disable();
                      this.alertaFinalIngreso();
                      this.contArchivos = 0;
                      this.cantidadArchivoCargado = 0;
                    }
                  }
                } else {
                  this.formReclamoGroup.disable();
                  this.alertaFinalIngreso();
                  this.contArchivos = 0;
                  this.cantidadArchivoCargado = 0;
                }
              }, 1000);
            }, 500);
          },
          (e) => {
            if (e.error) {
              this.alertaPSM(e.error);
            }
          }
        );
      }
      //llena loa datos del formulario
    } //Fin de alerta
  }

  async llenarBodyConCamposDeFormulario() {
    // Se obtiene el modelo inicializado a vacío
    const data: any = {
      reclamo: {
        idReclamo: 0,
        codReclamo: "",
        idProducto: 0,
        descripcionProducto: "",
        idServicio: 0,
        descripcioServicio: "",
        idMotivo: 0,
        descripcionMotivo: "",
        idOrigen: 0,
        origen: "",
        desOrigen: "",
        desTipoPersona: "",
        tipoPersona: 0,
        idCanal: 0,
        desCanal: "",
        idTipoDocumento: 0,
        idOficialCuenta: "",
        desTipoDocumento: "",
        identificacionCliente: "",
        nombreCliente: "",
        nombreOficialCuenta: "",
        telefono1: "",
        telefono2: "",
        telefono: "",
        direccion: "",
        provincia: "",
        ciudad: "",
        ciudadOficial: "",
        sucursal: "",
        numeroOficinaReclamo: 0,
        nombreOficinaReclamo: "",
        monto: "",
        ciudadOficina: "",
        telefonoOficina: "",
        correo: "",
        numeroOficinaOrigenReclamo: 0,
        nombreOficinaOrigenReclamo: "",
        detalleReclamo: "",
        peticionConcreta: "",
        accionOperativa: "",
        estadoTiempoSBE: "",
        IdFavorableDesfavorable: 0,
        desFavorableDesfavorable: "",
        solucionProblema: "",
        diasUtilizados: 0,
        plazoNormativa: 0,
        plazoBanco: 0,
        departamentOrigenReclamo: 0,
        usuarioRegistro: "",
        IpRegistro: "",
        usuarioModificacion: "",
        IpModificacion: "",
        idEstadoTiempoSBE: 0,
        diasSuper: 0,
        operacion: "",
        notificar: false,
        formulario: "",
        estadoReclamo: 0,
        estadoTiempoBanco: 0,
        favorableDesfavorable: 0,
        IdentApoderado: "",
        NombreApoderado: "",
        ApellidoApoderado: "",
        CampoEstado: "",
        CampoDescripcion: "",
        isDefensor: false,
        ListaCamposAdi: [{}],
        Segmento: "",
        CodigoMis: "",

        fechaNacimiento: null,
        idGenero: 0,
        idProvincia: 0,
        idCanton: 0,
        codigoSib: "",

        fechaProblema: Date,

        tipocuentades: this.tipocuentades,
      },
      token: { TokenId: "" },
    };
    this.body = data;
    // Se sobreescriben los campos con los valores ingresados por el usuario
    // Se valida por origen de reclamo
    this.body.reclamo.origen = sessionStorage.origen;
    switch (sessionStorage.origen) {
      case "defensor":
        this.body.reclamo.idOrigen = this.dataConfig["origenDefensor"];
        this.body.reclamo.isDefensor = true;
        break;
      case "cliente":
        this.body.reclamo.idOrigen = this.dataConfig["origenCliente"];
        this.body.reclamo.isDefensor = false;
        break;
      case "call_Center":
        this.body.reclamo.idOrigen = this.dataConfig["idCanalCallCenter"];
        this.body.reclamo.isDefensor = false;
        break;
    }
    this.body.reclamo.idOficialCuenta = this.dataCliente.codigoOficial;
    this.body.reclamo.IpRegistro = " ";

    this.body.reclamo.nombreOficinaOrigenReclamo =
      this.formReclamoGroup.get("oficinaIngreso").value;
    this.body.reclamo.nombreOficinaReclamo =
      this.formReclamoGroup.get("oficinaIngreso").value;
    this.body.reclamo.idServicio =
      this.formReclamoGroup.get("servicio").value.IdServicio;
    this.body.reclamo.descripcioServicio =
      this.formReclamoGroup.get("servicio").value.Descripcion;
    if (!this.isActiveUrl) {
      this.body.reclamo.idCanal = this.dataConfig["idCanal"];
      this.body.reclamo.desCanal = this.dataConfig["descCanal"];
    } else {
      this.body.reclamo.idCanal = this.dataConfig["idCanalCallCenter"];
      this.body.reclamo.desCanal = this.dataConfig["descCallCenter"];
    }
    this.body.reclamo.direccion = this.dataCliente.direccion;
    this.body.reclamo.nombreOficialCuenta =
      this.dataCliente.nombreOficialCuenta;
    this.body.reclamo.correo = this.dataCliente.correo;
    this.body.reclamo.telefono1 = this.dataCliente.telefono1;
    this.body.reclamo.telefono2 = this.dataCliente.telefono2;
    this.body.reclamo.provincia = this.formReclamoGroup.get("provincia").value;
    this.body.reclamo.ciudad = this.formReclamoGroup.get("ciudad").value;
    this.body.reclamo.CodigoMis = this.dataCliente.CodigoMis;
    //* nuevos campos

    this.body.reclamo.tarjeta = this.tarjeta;

    this.body.reclamo.fechaNacimiento =
      sessionStorage.tipoPersona != "R"
        ? new Date(this.dataCliente.fechaNacimiento)
        : null;
    this.body.reclamo.idGenero =
      sessionStorage.tipoPersona != "R" ? this.generoId : null;
    this.body.reclamo.idProvincia = parseInt(this.provinciaId, 10);
    this.body.reclamo.idCanton = this.cantonId;
    this.body.reclamo.codigoSib =
      sessionStorage.tipoPersona === "P" ? this.dataCliente.codigoSib : "";

    // Se valida por tipo de persona
    if (sessionStorage.tipoPersona === "C") {
      this.body.reclamo.tipoPersona = 41;
      this.body.reclamo.desTipoPersona = "Natural";
      this.body.reclamo.desTipoDocumento = "Cedula";
      this.body.reclamo.identificacionCliente =
        this.formReclamoGroup.get("identificacion").value;
      this.body.reclamo.nombreCliente =
        this.formReclamoGroup.get("nombres").value;
    } else if (sessionStorage.tipoPersona === "R") {
      this.body.reclamo.tipoPersona = 42;
      this.body.reclamo.desTipoPersona = "Juridica";
      this.body.reclamo.desTipoDocumento = "Ruc";
      this.body.reclamo.identificacionCliente =
        this.formReclamoGroup.get("ruc").value;
      this.body.reclamo.nombreCliente =
        this.formReclamoGroup.get("razonSocial").value;
    } else if (sessionStorage.tipoPersona === "P") {
      this.body.reclamo.tipoPersona = 43;
      this.body.reclamo.desTipoPersona = "Pasaporte";
      this.body.reclamo.desTipoDocumento = "PAS";
      this.body.reclamo.identificacionCliente =
        this.formReclamoGroup.get("identificacion").value;
      this.body.reclamo.nombreCliente =
        this.formReclamoGroup.get("nombres").value;
    }
    this.body.reclamo.telefono = this.formReclamoGroup.get("telOficial").value;
    this.body.reclamo.idProducto =
      this.formReclamoGroup.get("producto").value.IdProducto;
    this.body.reclamo.descripcionProducto =
      this.formReclamoGroup.get("producto").value.descripcion;
    this.body.reclamo.idMotivo =
      this.formReclamoGroup.get("motivo").value.idMotivos;
    this.body.reclamo.descripcionMotivo =
      this.formReclamoGroup.get("motivo").value.descripcion;
    const monto = (<HTMLInputElement>document.getElementById("montoReclamo"))
      .value;
    this.body.reclamo.monto = monto ? monto : "$0.0";
    this.body.reclamo.detalleReclamo = this.formReclamoGroup
      .get("descHechos")
      .value.replace(this.regex, "");
    this.body.reclamo.peticionConcreta = this.formReclamoGroup
      .get("peticion")
      .value.replace(this.regex, "");

    this.body.reclamo.Segmento = this.dataCliente.Segmento;
    this.body.reclamo.fechaProblema =
      this.formReclamoGroup.get("fechaProblema").value;
    if (this.dataCamposAdicionales === undefined) {
      this.body.reclamo.ListaCamposAdi = [];
    } else {
      this.body.reclamo.ListaCamposAdi = this.dataCamposAdicionales.Campos
        ? this.dataCamposAdicionales.Campos
        : [];
    }
    // Añade los datos elegidos de la tabla al body
    /*    for (const iterator of this.productosCliente) {
      if (iterator.seleccionar) {
        this.codOperacion = iterator.NumeroCuenta;
        this.body.reclamo.operacion = iterator.NumeroCuenta;
        if (iterator.Descripcion) {
          this.body.reclamo.CampoDescripcion = iterator.Descripcion;
        }
      }
    } */

    if (this.cuentaSeleccionada != null) {
      this.codOperacion = this.cuentaSeleccionada.numerocuenta;
      this.body.reclamo.operacion = this.cuentaSeleccionada.numerocuenta;
    }
    this.body.reclamo.CampoEstado =
      this.formReclamoGroup.controls.tipoCuenta.value;
  }

  async generarPdf() {
    // Se recolecta la data para enviar al pdf
    let tipoD: any;
    const documento = this.formReclamoGroup.controls.tipoDocumento.value;
    if (documento == "Cedula") {
      tipoD = "Cédula";
    } else if (documento == "Ruc") {
      tipoD = "Ruc";
    } else {
      tipoD = "Pasaporte";
    }
    const data = {
      codReclamo: this.formReclamoGroup.get("reclamo").value,
      fecha: this.formReclamoGroup.get("fecha").value,
      entidad: "Banco Bolivariano",
      sucursal: this.formReclamoGroup.get("oficinaIngreso").value,
      nombreEjecutivo: this.formReclamoGroup.get("oficialDeCuenta").value,
      usuarioNombres: this.formReclamoGroup.get("nombres").value,
      tipoDocumento: tipoD,
      identificacion: this.formReclamoGroup.get("identificacion").value,
      telefono1: this.formReclamoGroup.get("telefono2").value,
      celular: this.formReclamoGroup.get("telefono1").value,
      direccion: this.formReclamoGroup.get("direccion").value,
      ciudad: this.formReclamoGroup.get("ciudad").value,
      provincia: this.formReclamoGroup.get("provincia").value,
      correo: this.formReclamoGroup.get("correo").value,
      monto: this.formReclamoGroup.get("montoReclamo").value
        ? this.formReclamoGroup.get("montoReclamo").value
        : 0.0,
      descHechos: this.formReclamoGroup
        .get("descHechos")
        .value.replace(this.regex, ""),
      peticion: this.formReclamoGroup
        .get("peticion")
        .value.replace(this.regex, ""),
      ruc: this.formReclamoGroup.get("ruc").value,
      razonSocial: this.formReclamoGroup.get("razonSocial").value,
      descProducto: this.formReclamoGroup.get("producto").value.descripcion,
      descServicio: this.formReclamoGroup.get("servicio").value.Descripcion,
      descReclamo: this.formReclamoGroup.get("motivo").value.descripcion,
      canal: this.dataConfig["descCanal"],
      docCed: this.docCed,
      codOperacion: this.codOperacion,
      docTrx: this.docTrx,
      docCarta: this.docCarta,
      docDenuncia: this.docDenuncia,
      cheques: this.cheques,
      atm: this.atm,
      tdebito: this.tdebito,
      tcredito: this.tcredito,
      operacionCredito: this.operacionCredito,
      centralRiesgo: this.centralRiesgo,
      certifDeposito: this.certifDeposito,
      tarifaServicio: this.tarifaServicio,
      listaCamposAdi: undefined,
      isDefensor: this.body.reclamo.isDefensor,
      tipocuentades: this.tipocuentades,
    };
    if (this.body.reclamo.origen === "cliente") {
      data.listaCamposAdi = this.dataCamposAdicionales.Campos;
    }
    await this.pdfMaker.generarPdf(data);
  }

  async subirArchivo() {
    this.spinner.show();
    if (this.file1.length > 0) {
      this.almacenarArchivos.forEach(async (element) => {
        element.idReclamo = Number(this.idReclamo);
        const en: any = await (
          await this.endPoint.getTokenApiGateway()
        ).toPromise();
        this.endPoint.setToken(en.token_type + " " + en.access_token);
        element.token.TokenId = en.access_token;
        this.coreService
          .httpGuardaArchivo(element)
          .toPromise()
          .catch((error) => {
            this.error.push(error.status);
          });
      });
    }
    if (this.file2.length > 0) {
      const postData = {
        idReclamo: 0,
        isFormulario: "",
        FileName: "",
        FileBase: "",
        token: {
          TokenId: "",
        },
      };
      let data: any;
      const formdata2 = new FileReader();
      formdata2.readAsDataURL(this.file2[0]);
      formdata2.onload = async () => {
        data = formdata2.result.toString().replace(/^data:(.*,)?/, "");
        if (data.length % 4 > 0) {
          data += "=".repeat(4 - (data.length % 4));
        }
        postData.idReclamo = Number(this.idReclamo);
        postData.isFormulario = "false";
        postData.FileBase = data;
        var fileExtension = "." + this.file2[0].name.split(".").pop();
        postData.FileName = "CopiaDeTransaccion" + fileExtension;
        const en: any = await (
          await this.endPoint.getTokenApiGateway()
        ).toPromise();
        this.endPoint.setToken(en.token_type + " " + en.access_token);
        postData.token.TokenId = en.access_token;
        /*         this.coreService.httpGuardaArchivo(postData).subscribe((data: any) => {
        
                }, error => {
                  this.error.push(error.status);
                }); */
        this.coreService
          .httpGuardaArchivo(postData)
          .toPromise()
          .catch((error) => {
            this.error.push(error.status);
          });
      };
    }
    if (this.file3.length > 0) {
      const postData = {
        idReclamo: 0,
        isFormulario: "",
        FileName: "",
        FileBase: "",
        token: {
          TokenId: "",
        },
      };
      let data: any;
      const formdata3 = new FileReader();
      formdata3.readAsDataURL(this.file3[0]);
      formdata3.onload = async () => {
        data = formdata3.result.toString().replace(/^data:(.*,)?/, "");
        if (data.length % 4 > 0) {
          data += "=".repeat(4 - (data.length % 4));
        }
        postData.idReclamo = Number(this.idReclamo);
        postData.isFormulario = "false";
        postData.FileBase = data;
        var fileExtension = "." + this.file3[0].name.split(".").pop();
        postData.FileName = "Carta" + fileExtension;
        const en: any = await (
          await this.endPoint.getTokenApiGateway()
        ).toPromise();
        this.endPoint.setToken(en.token_type + " " + en.access_token);
        postData.token.TokenId = en.access_token;
        /*         this.coreService.httpGuardaArchivo(postData).subscribe((data: any) => {
        
                }, error => {
                  this.error.push(error.status);
                }); */
        this.coreService
          .httpGuardaArchivo(postData)
          .toPromise()
          .catch((error) => {
            this.error.push(error.status);
          });
      };
    }
    if (this.file4.length > 0) {
      const postData = {
        idReclamo: 0,
        isFormulario: "",
        FileName: "",
        FileBase: "",
        token: {
          TokenId: "",
        },
      };
      let data: any;
      const formdata4 = new FileReader();
      formdata4.readAsDataURL(this.file4[0]);
      formdata4.onload = async () => {
        data = formdata4.result.toString().replace(/^data:(.*,)?/, "");
        if (data.length % 4 > 0) {
          data += "=".repeat(4 - (data.length % 4));
        }
        postData.idReclamo = Number(this.idReclamo);
        postData.isFormulario = "false";
        postData.FileBase = data;
        var fileExtension = "." + this.file4[0].name.split(".").pop();
        postData.FileName = "Denuncia" + fileExtension;
        const en: any = await (
          await this.endPoint.getTokenApiGateway()
        ).toPromise();
        this.endPoint.setToken(en.token_type + " " + en.access_token);
        postData.token.TokenId = en.access_token;
        /*         this.coreService.httpGuardaArchivo(postData).subscribe((data: any) => {
        
                }, error => {
                  this.error.push(error.status);
                }); */
        this.coreService
          .httpGuardaArchivo(postData)
          .toPromise()
          .catch((error) => {
            this.error.push(error.status);
          });
      };
    }
    if (this.pdfMaker.datapdf !== "") {
      const postData = {
        idReclamo: 0,
        isFormulario: "",
        FileName: "",
        FileBase: "",
        token: {
          TokenId: "",
        },
      };
      postData.idReclamo = Number(this.idReclamo);
      postData.isFormulario = "true";
      postData.FileBase = this.pdfMaker.datapdf;
      postData.FileName = "formularioreclamo.pdf";
      const en: any = await (
        await this.endPoint.getTokenApiGateway()
      ).toPromise();
      this.endPoint.setToken(en.token_type + " " + en.access_token);
      postData.token.TokenId = en.access_token;
      /*       this.coreService.httpGuardaArchivo(postData).subscribe((data: any) => {
      
            }, error => {
              this.error.push(error.status);
            }); */
      this.coreService
        .httpGuardaArchivo(postData)
        .toPromise()
        .catch((error) => {
          this.error.push(error.status);
        });
    }
    this.spinner.hide();
  }

  alertaFinalIngreso() {
    const dialogRef = this.dialog.open(DialogAlertComponent, {
      data: {
        name: "Banco Bolivariano",
        content:
          "Su reclamo ha sido ingresado de manera exitosa. \nEl código de su reclamo es: " +
          this.idReclamo,
        buttons: false,
        btnAceptar: false,
        btnCancelar: true,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (sessionStorage.origen === "call_Center") {
          window.close();
        } else {
          let url = "/consultas";
          this.router.navigateByUrl(url);
        }
        /*  if (sessionStorage.origen === 'defensor') {
           window.location.href = this.jsonConfig['urlSitio'] + '/#/inicio?origen=defensor';
         } else {
           window.location.href = this.jsonConfig['urlSitio'] + '/#/inicio?origen=cliente';
         } */
      }
    });
  }

  onKey(event) {
    this.contadorText = event.target.value.length;
    this.ms = this.contadorText + "/1000 caracteres";
  }

  onKey1(event) {
    this.contadorText = event.target.value.length;
    this.ms1 = this.contadorText + "/1000 caracteres";
  }

  letterOnly($event: KeyboardEvent) {
    if (
      ($event.keyCode >= 33 && $event.keyCode <= 64) ||
      ($event.keyCode >= 91 && $event.keyCode <= 96) ||
      ($event.keyCode >= 123 && $event.keyCode <= 255)
    ) {
      return false;
    }
    return true;
  }

  numberOnly($event: KeyboardEvent) {
    if (
      $event.keyCode <= 45 ||
      $event.keyCode > 57 ||
      $event.keyCode == 47 ||
      $event.keyCode == 46
    ) {
      return false;
    }
    return true;
  }

  alertaValidacion(msg: string) {
    var dialog4 = this.dialog.open(DialogAlertComponent, {
      data: {
        name: "Validación",
        content: msg,
        buttons: false,
        btnAceptar: false,
        btnCancelar: true,
      },
    });
    return dialog4;
  }

  alertaCuenta(msg: string) {
    var dialog3 = this.dialog.open(DialogAlertComponent, {
      data: {
        name: "Tipo de Cuenta",
        content: msg,
        buttons: false,
        btnAceptar: false,
        btnCancelar: true,
      },
    });
    return dialog3;
  }

  alertaValorReclamo(msg: string) {
    var dialog = this.dialog.open(DialogAlertComponent, {
      data: {
        name: "Valor del Reclamo",
        content: msg,
        buttons: false,
        btnAceptar: false,
        btnCancelar: true,
      },
    });
    return dialog;
  }

  alertaCampoReqRec(msg: string, campo: string) {
    var dialog1 = this.dialog.open(DialogAlertComponent, {
      data: {
        name: campo,
        content: msg,
        buttons: false,
        btnAceptar: false,
        btnCancelar: true,
      },
    });
    return dialog1;
  }

  alertaPSM(msg: string) {
    var dialog2 = this.dialog.open(DialogAlertComponent, {
      data: {
        name: "Banco Bolivariano",
        content: msg,
        buttons: false,
        btnAceptar: false,
        btnCancelar: true,
      },
    });
    return dialog2;
  }

  get formValues() {
    return this.formReclamoGroup.controls;
  }

  retornaNombreCampo(campo: string) {
    var nomCampo: string;
    switch (campo) {
      case "ca-direccionEnvioRes":
        nomCampo = "Dirección de envío respuesta";
        break;
      case "ca-telefonoCelular":
        nomCampo = "Telefono celular";
        break;
      case "ca-fecha":
        nomCampo = "Fecha";
        break;
      case "ca-operadoraTele":
        nomCampo = "Operadora de telefono";
        break;
      case "ca-valor":
        nomCampo = "Valor de la transacción";
        break;
      case "ca-valorReclamo":
        nomCampo = "Valor del reclamo";
        break;
      case "ca-tipodeservicio":
        nomCampo = "Tipo de seervicio";
        break;
      case "ca-fechaDebitoCredito":
        nomCampo = "Fecha de Debito/Credito";
        break;
      case "ca-ciuDirEntregaReso":
        nomCampo = "Ciudad de entrega de respuesta";
        break;
      case "ca-fechaConsumo":
        nomCampo = "Fecha de consumo";
        break;
      case "ca-atmCajeroNo":
        nomCampo = "Número de cajero";
        break;
      case "ca-trasaccionNo":
        nomCampo = "Número de transacción";
        break;
      case "ca-guiaRemisio":
        nomCampo = "Guia de remisión";
        break;
      case "ca-numeroFunda":
        nomCampo = "Número de funda";
        break;
      case "ca-valorAjuste":
        nomCampo = "Valor de ajuste";
        break;
      case "ca-valorTotalDeposito":
        nomCampo = "Valor total de deposito";
        break;
      case "ca-operador":
        nomCampo = "Operador";
        break;
      case "ca-comercio":
        nomCampo = "Comercio";
        break;
      case "ca-referencia":
        nomCampo = "Referencia";
        break;
      case "ca-estadoTarjeta":
        nomCampo = "Estado de tarjeta";
        break;
      case "ca-numeroCancelacion":
        nomCampo = "Número de cancelación";
        break;
      case "ca-valorErrado":
        nomCampo = "Valor errado";
        break;
      case "ca-Banco":
        nomCampo = "Banco";
        break;
      case "ca-direccióncajero":
        nomCampo = "Dirección de cajero";
        break;
      case "ca-numeroCelular":
        nomCampo = "Número de celular";
        break;
      case "ca-cuentaPerteneceDoc":
        nomCampo = "Cuenta Pertenece al Documento";
        break;
      case "ca-peridoTributario":
        nomCampo = "Periodo tributario";
        break;
      case "ca-ruc":
        nomCampo = "Ruc";
        break;
      case "ca-tipoImpuesto":
        nomCampo = "Tipo de impuesto";
        break;
      case "ca-numeroOrden":
        nomCampo = "Número de orden";
        break;
      case "ca-codigoMedidorPlantilla":
        nomCampo = "Código de medidor de plantilla";
        break;
      case "ca-EmpresaColegio":
        nomCampo = "Empresa/Colegio";
        break;
      case "ca-matricula":
        nomCampo = "Matricula";
        break;
      case "ca-oficinaAtencion":
        nomCampo = "Ofinica de atención";
        break;
      case "ca-nombFuncionarioQueja":
        nomCampo = "Nombre de funcionario de Queja";
        break;
      case "ca-horaTrans":
        nomCampo = "Horas de transacción";
        break;
      case "ca-rubroDiferencia":
        nomCampo = "Rubro de diferencia";
        break;
      case "ca-fechaPrimerDebito":
        nomCampo = "Fecha del primer debito";
        break;
      case "ca-fechaUltimoDebito":
        nomCampo = "Fecha del ultimo debito";
        break;
      case "ca-chequesNos":
        nomCampo = "Cheque";
        break;
      case "ca-cuentaCorriente":
        nomCampo = "Cuenta corriente";
        break;
      case "ca-autorizolacompra":
        nomCampo = "Autorización de la compra";
        break;
      case "ca-emailparaenvioderespuesta":
        nomCampo = "Email para envio de respuesta";
        break;
      case "ca-beneficiario":
        nomCampo = "Beneficiario";
        break;
      default:
        nomCampo = campo;
        break;
    }
    return nomCampo;
  }

  async guardarDetalleReclamo(idReclamo: any) {
    const en: any = await (
      await this.endPoint.getTokenApiGateway()
    ).toPromise();

    const data: any = {
      detalleReclamo:
        this.transaccionesSeleccionadas.transaccionesSeleccionadasTDTC[0],
      token: { TokenId: "" },
    };

    data.detalleReclamo.idReclamo = idReclamo;
    data.detalleReclamo.IpRegistro = " ";
    data.detalleReclamo = this.convertEmptyStringToNull(data.detalleReclamo);

    data.token.TokenId = en.access_token;

    //las variables que esten vacias poner null

    this.endPoint.setToken(en.token_type + " " + en.access_token);
    this.spinner.show();
    this.coreService.httpDetalleReclamo(data).subscribe(
      (data: any) => {},
      (error) => {
        this.alertaValidacion("Error al guardar el detalle");
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  convertEmptyStringToNull(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === "") {
          obj[key] = null;
        }
      }
    }

    return obj;
  }

  // fin
}
